import * as React from 'react'
import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import MultipleOptions from './MultipleOptions'
import CustomRadioGroup from './CustomRadioGroup'
import PersonalData from './PersonalData'
import localStorageService from '../services/localStorageService'
import UsageChoice, { TRANSPORT_TYPES } from './UsageChoice'
import skipPersonaSuvey from '../api/skipPersonaSuvey'
import axios from 'axios'
import Strings from '../strings'
import { useParams } from 'react-router-dom'
import { getCityHeader } from '../api/request'
import { getUserDataFromIDServer } from '../api'
import { TOP_LEVEL_DOMAIN } from '../cities'

import './PretestStepper.css'

const useStyles = makeStyles(theme => ({
	dots: {
		backgroundColor: '#83b200',
	},
	dot: {
		backgroundColor: '#2d2147',
	},
}))

const start_title = (
	<span>
		{Strings.getMessage('Survey.Title--1/2')} <br />
		{Strings.getMessage('Survey.Title--2/2')}
	</span>
)

const steps = [
	{
		label: start_title,
		body: (
			<div style={{ fontFamily: 'DM Sans', color: '#2d2047', lineHeight: '2em', fontSize: 12 }}>
				{/* <h2 style={{ fontSize: 16, margin: '8px 0 8px 0', padding: 0 }}>Bewertung der Zürcher Velorouten</h2> */}
				<p>{Strings.getMessage('Survey.StepIntro.Description')}</p>
				<p>{Strings.getMessage('Survey.StepIntro.ListParagraph')}</p>
				<div>
					<ul>
						<li>{Strings.getMessage('Survey.StepIntro.ListItem')}</li>
					</ul>
					<div>{Strings.getMessage('Survey.StepIntro.Footer')}</div>
				</div>
			</div>
		),
	},
	{
		label: Strings.getMessage('Survey.PersonalInfo.Label'),
		body: ({ personalData, setPersonalData, user }) => (
			<div style={{ color: '#2d2047', fontFamily: 'DM Sans' }}>
				<PersonalData
					data={personalData}
					onChange={data => {
						setPersonalData(data)
					}}
				/>
			</div>
		),
	},
	{
		label: Strings.getMessage('Survey.ThankYou.Label'),
		body: (
			<div style={{ fontFamily: 'DM Sans', color: '#2d2047', lineHeight: '2em', fontSize: 12 }}>
				<p>{Strings.getMessage('Survey.ThankYou.Description')}</p>
				<p>{Strings.getMessage('Survey.ThankYou.Footer')}</p>
			</div>
		),
	},
	{
		label: Strings.getMessage('Survey.HowOftenDoYouUse.Label'),
		body: ({ sliderState, setSliderState, updateSlider, max, setMax, updateUsageChoice, userUsageChoices }) => (
			<div style={{ fontFamily: 'DM Sans', color: '#2d2047' }}>
				<UsageChoice userUsageChoices={userUsageChoices} updateUsageChoice={updateUsageChoice} />
				{/* <DescreteSlider
					locked={sliderState.walking.lock}
					onLock={lock => {
						if (lock) {
							setMax(max - sliderState['walking'].pct)
						} else {
							setMax(max + sliderState['walking'].pct)
						}
						const newState = { ...sliderState.walking, ...{ lock: lock } }
						setSliderState({ ...sliderState, walking: newState })
					}}
					defaultValue={25}
					id='walking'
					max={max}
					onChange={pct => {
						updateSlider('walking', pct)
					}}
					name={'Zu Fuss'}
				/>
				<DescreteSlider
					locked={sliderState.biking.lock}
					onLock={lock => {
						if (lock) {
							setMax(max - sliderState['biking'].pct)
						} else {
							setMax(max + sliderState['biking'].pct)
						}
						const newState = { ...sliderState.biking, ...{ lock: lock } }
						setSliderState({ ...sliderState, biking: newState })
					}}
					defaultValue={25}
					id='biking'
					max={max}
					onChange={pct => {
						updateSlider('biking', pct)
					}}
					name={'Velo'}
				/>
				<DescreteSlider
					locked={sliderState.pt.lock}
					onLock={lock => {
						if (lock) {
							setMax(max - sliderState['pt'].pct)
						} else {
							setMax(max + sliderState['pt'].pct)
						}
						const newState = { ...sliderState.pt, ...{ lock: lock } }
						setSliderState({ ...sliderState, pt: newState })
					}}
					defaultValue={25}
					id='pt'
					max={max}
					onChange={pct => {
						updateSlider('pt', pct)
					}}
					name={'ÖV: Tram, Bus, Bahn'}
				/>
				<DescreteSlider
					locked={sliderState.car.lock}
					onLock={lock => {
						if (lock) {
							setMax(max - sliderState['car'].pct)
						} else {
							setMax(max + sliderState['car'].pct)
						}
						const newState = { ...sliderState.car, ...{ lock: lock } }
						setSliderState({ ...sliderState, car: newState })
					}}
					defaultValue={25}
					id='car'
					max={max}
					onChange={pct => {
						updateSlider('car', pct)
					}}
					name={'MIV: Auto, Motorrad'}
				/> */}
			</div>
		),
	},
	{
		label: Strings.getMessage('Survey.WhichBike.Label'),
		body: ({ bike, setBike }) => (
			<div style={{ color: '#2d2047', fontFamily: 'DM Sans', maxHeight: window.innerHeight - 240, overflow: 'auto' }}>
				<MultipleOptions
					init={bike}
					key='bike'
					onChange={bike => {
						setBike(bike)
					}}
					options={[
						{ name: `citybike`, label: Strings.getMessage('Survey.WhichBike.EverydayBike') },
						{ name: `e-bike25km`, label: Strings.getMessage('Survey.WhichBike.E-Bike25') },
						{ name: `e-bike45km`, label: Strings.getMessage('Survey.WhichBike.E-Bike45') },
						{ name: `sportvelo`, label: Strings.getMessage('Survey.WhichBike.SportsBike') },
						{ name: `tourenbike`, label: Strings.getMessage('Survey.WhichBike.Travel') },
						{ name: 'cargobike', label: Strings.getMessage('Survey.WhichBike.Cargo') },
						{ name: 'bikesharing', label: Strings.getMessage('Survey.WhichBike.Sharing') },
						{ name: `andere`, label: Strings.getMessage('Survey.WhichBike.Other') },
					]}
				/>
			</div>
		),
	},
	{
		label: Strings.getMessage('Survey.WhatPurpose.Label'),
		body: ({ usage, setUsage }) => (
			<div style={{ color: '#2d2047', fontFamily: 'DM Sans' }}>
				<MultipleOptions
					init={usage}
					key='usage'
					onChange={usage => {
						setUsage(usage)
					}}
					options={[
						{ name: `arbeit`, label: Strings.getMessage('Survey.WhatPurpose.Work') },
						{ name: `ausbildung`, label: Strings.getMessage('Survey.WhatPurpose.Education') },
						{ name: `freizeit_kultur_sport`, label: Strings.getMessage('Survey.WhatPurpose.Leisure') },
						{
							name: `einkauf_gastronomie_dienstleistungen`,
							label: Strings.getMessage('Survey.WhatPurpose.Shopping'),
						},
						{ name: `ferien-reisen`, label: Strings.getMessage('Survey.WhatPurpose.Holidays') },
						{ name: `andere`, label: Strings.getMessage('Survey.WhatPurpose.Other') },
					]}
				/>
			</div>
		),
	},
	{
		label: Strings.getMessage('Survey.AreThereChildren.Label'),
		body: ({ children, setChildren }) => (
			<div style={{ color: '#2d2047', fontFamily: 'DM Sans' }}>
				<CustomRadioGroup
					init={children}
					onChange={children => {
						setChildren(children)
					}}
					key='children'
					options={[
						{ name: `ja`, label: Strings.getMessage('Survey.AreThereChildren.Yes') },
						{ name: 'nein', label: Strings.getMessage('Survey.AreThereChildren.No') },
						{ name: `keine_angabe`, label: Strings.getMessage('Survey.AreThereChildren.NoInfo') },
					]}
				/>
			</div>
		),
	},
	// {
	//   label: 'Strecke pro Tag?',
	//   body: <div>
	//     <CustomRadioGroup options={
	//       [
	//         { name: `l1`, label: `Unter 1 km` },
	//         { name: `f1t3`, label: `1-3 km` },
	//         { name: `f3t5`, label: `3-5 km` },
	//         { name: `f5t10`, label: `5-10 km` },
	//         { name: `f10to15`, label: `10-15 km` },
	//         { name: `m15`, label: `Über 15 km` },
	//       ]
	//     } />
	//   </div>
	// },
	// {
	// 	label: 'Konto erstellen*',
	// 	body: ({ signupData, setSignupData }) => (
	// 		<div style={{ color: '#2d2047', fontFamily: 'DM Sans' }}>
	// 			<Signup data={signupData} onChange={data => setSignupData(data)} />
	// 		</div>
	// 	),
	// },
]

export default function PretestStepper() {
	const theme = useTheme()
	const classes = useStyles()
	const [activeStep, setActiveStep] = React.useState(0)
	const maxSteps = steps.length - 3

	const [finished, setFinished] = React.useState(false)
	const [error, setError] = React.useState(false)
	const [user, setUser] = React.useState(localStorageService.getUser())

	const { state, city } = useParams()

	const getURLPrefix = () => {
		if (!!state) {
			if (!!city) {
				return `/${state}/${city}`
			} else {
				return `/${state}`
			}
		}

		return ''
	}

	React.useEffect(async () => {
		// get data from datamap about the user if user has a token
		if (user) {
			try {
				const dmUser = await getUserDataFromIDServer(user.jwt)

				const { data } = dmUser.data

				setUser(data)
				setPersonalData({
					birthyear: data.birthyear,
					gender: data.gender === 0 ? 'male' : data.gender === 1 ? 'female' : data.gender === 2 ? 'Other' : false,
					city: data.city,
					country: data.country,
					postcode: data.postcode,
				})
				setSignupData({
					email: data.email,
				})
			} catch (err) {}
		}
	}, [])

	const [sliderState, setSliderState] = React.useState({
		walking: { pct: 25, lock: false, id: 'walking' },
		biking: { pct: 25, lock: false, id: 'biking' },
		pt: { pct: 25, lock: false, id: 'pt' },
		car: { pct: 25, lock: false, id: 'car' },
	})

	const [userUsageChoices, setUserUsageChoices] = React.useState({})

	const [max, setMax] = React.useState(100)

	const [bike, setBike] = React.useState({})
	const [usage, setUsage] = React.useState({})
	const [children, setChildren] = React.useState({})
	const [personalData, setPersonalData] = React.useState({
		birthyear: '',
		postcode: '',
		city: '',
		country: '',
		gender: false,
	})
	const [signupData, setSignupData] = React.useState({
		email: '',
		password: '',
	})

	const isNextDisabled = activeStep => {
		switch (activeStep) {
			case 0:
				return false
			case 1:
				for (let o in personalData) {
					if (!!personalData[o] === false) {
						return true
					}
				}
				return false
			case 2:
				return false
			case 3:
				for (let transportType of TRANSPORT_TYPES) {
					if (userUsageChoices[transportType.key] === undefined) {
						return true
					}
				}

				return false
			case 4:
				return false
			case 5:
				return false
			case 6:
				for (let o in children) {
					if (children[o]) {
						return false
					}
				}
				return true
			case 7:
				// if (!!signupData.email === false || !!signupData.password === false) {
				// 	return true
				// }
				// if (signupData.password.length < 5) {
				// 	return true
				// }

				return false
			default:
				return true
		}
	}

	const updateUsageChoice = (name, value) => {
		setUserUsageChoices({ ...userUsageChoices, [name]: value })
	}

	const updateSlider = (id, pct) => {
		const ids = ['walking', 'biking', 'pt', 'car']

		const targets = ids.filter(origin => {
			return origin !== id && sliderState[origin].lock === false
		})
		const toDistribute = pct - sliderState[id].pct

		let next = 0
		if (Math.abs(toDistribute / targets.length) >= 0) {
			targets.forEach((target, i) => {
				if (i === 0 && toDistribute % targets.length !== 0) {
					sliderState[target].pct -= toDistribute % targets.length
				}

				if (sliderState[target].pct - parseInt(toDistribute / targets.length) < 0) {
					next += sliderState[target].pct - parseInt(toDistribute / targets.length)
					sliderState[target].pct = 0
				} else {
					sliderState[target].pct -= parseInt(toDistribute / targets.length)
				}

				document.querySelector(`#${target} .MuiSlider-thumb`).style.left = sliderState[target].pct + '%'
				document.querySelector(`#${target} .MuiSlider-thumb span span span`).innerHTML = sliderState[target].pct

				document.querySelector(`#${target} .MuiSlider-track`).style.width = sliderState[target].pct + '%'
				document.querySelectorAll(`#${target} .MuiSlider-markLabel`).forEach(item => {
					if (parseInt(item.style.left) > sliderState[target].pct) {
						item.classList.remove('MuiSlider-markLabelActive')
					}
				})
			})

			if (Math.abs(next) > 0) {
				for (let i = 0; i < targets.length; i++) {
					const target = targets[i]
					if (sliderState[target].pct + next >= 0) {
						sliderState[target].pct += next

						document.querySelector(`#${target} .MuiSlider-thumb`).style.left = sliderState[target].pct + '%'
						document.querySelector(`#${target} .MuiSlider-track`).style.width = sliderState[target].pct + '%'
						document.querySelectorAll(`#${target} .MuiSlider-markLabel`).forEach(item => {
							if (parseInt(item.style.left) > sliderState[target].pct) {
								item.classList.remove('MuiSlider-markLabelActive')
							}
						})
						break
					}
				}
			}
		}

		sliderState[id].pct = pct
		setSliderState({ ...sliderState })
	}

	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1)
	}

	const handleBack = () => {
		setError(false)
		setActiveStep(prevActiveStep => prevActiveStep - 1)
	}

	const onAbort = () => {
		skipPersonaSuvey()
			.then(data => data.data)
			.then(persona => {
				const { data } = persona
				if (data.length) {
					const dict = {}
					data.forEach(d => {
						if (!dict[d.topic]) {
							dict[d.topic] = {}
						}

						dict[d.topic] = { ...dict[d.topic], [d.key]: d.value }
					})
					localStorageService.setUserPretestPanel(dict)
					window.location.href = getURLPrefix()
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	const onGoToRating = () => {
		skipPersonaSuvey()
			.then(data => data.data)
			.then(persona => {
				const { data } = persona
				if (data.length) {
					const dict = {}
					data.forEach(d => {
						if (!dict[d.topic]) {
							dict[d.topic] = {}
						}

						dict[d.topic] = { ...dict[d.topic], [d.key]: d.value }
					})
					localStorageService.setUserPretestPanel(dict)
					window.location.href = getURLPrefix() + '/general-rating-photos'
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	const handleFinish = () => {
		if (user) {
			const data = {
				bike_type: bike,
				bike_usage: usage,
				children: children,
				// transport_mode: {
				// 	fussgaenger_pct: sliderState['walking'].pct,
				// 	velo_pct: sliderState['biking'].pct,
				// 	oev_pct: sliderState['pt'].pct,
				// 	miv_pct: sliderState['car'].pct,
				// },
				transport_usage: userUsageChoices,
				user: {
					...signupData,
					...personalData,
					id: user.id,
				},
			}
			// store pretest data to localstorage
			localStorageService.setUserPretestPanel(data)

			// update datamap account
			return axios
				.post(
					`${process.env.REACT_APP_ID_SERVER}/api/user/update`,
					{ ...data.user, gender: data.user.gender === 'male' ? 0 : data.user.gender === 'female' ? 1 : 2 },
					{
						headers: {
							Authentication: localStorageService.getUser().jwt,
						},
					},
				)
				.then(() => {
					// do another call to store data mapping id to email on posmo coop server
					const city = getCityHeader()
					return axios.post(`${process.env.REACT_APP_API}/v1/addPersona`, data, { headers: { city } })
				})
				.then(() => {
					setError(false)
					setFinished(true)

					// redirect to bike index.
					window.location.href = getURLPrefix()
				})
				.catch(err => {
					console.log(err)
					setError(<Typography style={{ color: 'red' }}>Error!</Typography>)
				})
		} else {
			// for new users
			axios
				.post(
					`${process.env.REACT_APP_ID_SERVER}/api/user/register`,
					{
						email: signupData.email,
						password: signupData.password,
					},
					{
						headers: {
							'Client-Api-Key': process.env.REACT_APP_CLIENT_API_KEY,
						},
					},
				)
				.then(registration => registration.data)
				.then(registration => {
					// store user data
					localStorageService.setUser(registration.data)

					const data = {
						bike_type: bike,
						bike_usage: usage,
						children: children,
						transport_mode: {
							fussgaenger_pct: sliderState['walking'].pct,
							velo_pct: sliderState['biking'].pct,
							oev_pct: sliderState['pt'].pct,
							miv_pct: sliderState['car'].pct,
						},
						user: {
							...signupData,
							...personalData,
							id: registration.data.id,
						},
					}
					// store pretest data to localstorage
					localStorageService.setUserPretestPanel(data)

					// update datamap account
					return axios
						.post(
							`${process.env.REACT_APP_ID_SERVER}/api/user/update`,
							{ ...data.user, gender: data.user.gender === 'male' ? 0 : data.user.gender === 'female' ? 1 : 2 },
							{
								headers: {
									Authentication: registration.data.jwt,
								},
							},
						)
						.then(() => {
							// do another call to store data mapping id to email on posmo coop server
							return axios.post(`${process.env.REACT_APP_API}/v1/addPersona`, data)
						})
				})
				.then(persona => persona.data)
				.then(persona => {
					setError(false)
					setFinished(true)
				})
				.catch(err => {
					console.log(err)
					setError(<Typography style={{ color: 'red' }}>Error!</Typography>)
				})
		}
	}

	return (
		<Box style={{ maxWidth: 290, margin: '0 auto' }}>
			<div style={{ textAlign: 'left', padding: '16px 0 0 0' }}>
				<img style={{ maxWidth: 120 }} alt='velobserver-logo (bicycle)' src='/assets/logo.svg' />
			</div>
			<Box style={{ margin: '0 auto', maxWidth: 290 }}>
				<Paper
					square
					elevation={0}
					style={{ padding: '12px 0 0 0', backgroundColor: '#f8f7f5' }}
					sx={{
						display: 'flex',
						alignItems: 'center',
						height: 50,
						pl: 2,
					}}>
					<Typography style={{ color: '#83b200', fontFamily: 'Recoleta', fontSize: 24, fontWeight: 600, padding: 0 }}>
						{finished ? 'Besten Dank für deine Anmeldung!' : steps[activeStep].label}
					</Typography>
				</Paper>
				<Box>
					{error ? error : ''}
					{!finished ? (
						typeof steps[activeStep].body === 'function' ? (
							steps[activeStep].body({
								sliderState,
								setSliderState,
								updateSlider,
								max,
								setMax,
								bike,
								setBike,
								usage,
								setUsage,
								children,
								setChildren,
								personalData,
								setPersonalData,
								signupData,
								setSignupData,
								user,
								updateUsageChoice,
								userUsageChoices,
							})
						) : (
							steps[activeStep].body
						)
					) : (
						<div>
							<p style={{ fontSize: 12 }}>
								{Strings.getMessage('Survey.Finis.Text--1/3')}{' '}
								<a href={`https://velobserver.${TOP_LEVEL_DOMAIN}`}>{Strings.getMessage('Survey.Finis.Text--2/3')}</a>{' '}
								{Strings.getMessage('Survey.Finis.Text--3/3')}
							</p>
						</div>
					)}
					{activeStep === 0 && !finished ? (
						<div className='BottomContainer'>
							<Button className='AbortButton' type='button' onClick={onAbort}>
								{Strings.getMessage('Abort')}
							</Button>
							<Button
								style={{
									fontWeight: 600,
									fontFamily: 'Recoleta',
									color: '#eee',
									backgroundColor: '#83b200',
									fontSize: 14,
									textTransform: 'none',
								}}
								onClick={handleNext}
								variant='contained'>
								{Strings.getMessage('Continue')}
							</Button>
						</div>
					) : (
						''
					)}
					{activeStep === 1 && !finished ? (
						<div className='BottomContainer'>
							<Button className='AbortButton' type='button' onClick={onAbort}>
								{Strings.getMessage('Abort')}
							</Button>
							<Button
								style={{
									fontWeight: 600,
									fontFamily: 'Recoleta',
									color: '#eee',
									backgroundColor: '#83b200',
									fontSize: 14,
									textTransform: 'none',
								}}
								disabled={isNextDisabled(activeStep)}
								onClick={handleNext}
								variant='contained'>
								{Strings.getMessage('SaveOnComputer')}
							</Button>
						</div>
					) : (
						''
					)}
					{activeStep === 2 && !finished ? (
						<div className='BottomContainer'>
							<Button className='AbortButton' type='button' onClick={onGoToRating}>
								{Strings.getMessage('Survey.StepIntro.ForEvaluation')}
							</Button>
							<Button
								style={{
									fontWeight: 600,
									fontFamily: 'Recoleta',
									color: '#eee',
									backgroundColor: '#83b200',
									fontSize: 14,
									textTransform: 'none',
								}}
								disabled={isNextDisabled(activeStep)}
								onClick={handleNext}
								variant='contained'>
								{Strings.getMessage('Survey.StepIntro.ToTheQuestions')}
							</Button>
						</div>
					) : (
						''
					)}
				</Box>
			</Box>
			{!finished && activeStep > 2 ? (
				<MobileStepper
					style={{ maxWidth: 290, margin: '0 auto', borderRadius: 8 }}
					variant='dots'
					classes={{ root: classes.dots, dotActive: classes.dot }}
					steps={maxSteps}
					// position="static"
					activeStep={activeStep - 3}
					nextButton={
						activeStep === maxSteps + 2 ? (
							<Button
								id='finished'
								disabled={isNextDisabled(activeStep)}
								style={{ fontWeight: 600, fontSize: 14, fontFamily: 'Recoleta', textTransform: 'none' }}
								size='small'
								onClick={handleFinish}>
								{Strings.getMessage('Complete')}
							</Button>
						) : (
							<Button
								style={{ fontWeight: 600, fontSize: 14, textTransform: 'none', fontFamily: 'Recoleta' }}
								key={`step${activeStep}`}
								disabled={isNextDisabled(activeStep)}
								size='small'
								onClick={handleNext}>
								{Strings.getMessage('Continue')} <KeyboardArrowRight />
							</Button>
						)
					}
					backButton={
						<Button
							style={{ fontWeight: 600, fontFamily: 'Recoleta', fontSize: 14, textTransform: 'none' }}
							size='small'
							onClick={handleBack}
							disabled={activeStep === 0}>
							{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
							{Strings.getMessage('Back')}
						</Button>
					}
				/>
			) : (
				''
			)}
		</Box>
	)
}
