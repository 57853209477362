import axios from 'axios'
import { AVAILABLE_CITIES, AVAILABLE_COUNTRIES } from '../cities'

export const getCityHeader = () => {
	const city = Object.keys(AVAILABLE_CITIES)[0]
	const location = window.location.href
	if (!location) {
		return city
	}

	const pathParts = location.split('/') // 0 - http ; 1 - ""; 2 - domen; 3 - start of url

	let countryCode = ''
	if (pathParts.length > 3) {
		const code = pathParts[3]
		if (AVAILABLE_CITIES[code]) {
			return code
		}

		if (AVAILABLE_COUNTRIES[code]) {
			countryCode = code
		} else {
			return city
		}
	}

	if (pathParts.length > 4) {
		const code = pathParts[4]
		if (AVAILABLE_COUNTRIES[countryCode][code]) {
			return code
		}
	}

	return city
}

function request(req) {
	const city = getCityHeader()
	req.headers = { ...req.headers, city }
	return axios(req)
}

class Request {
	static get({ params = {}, url, headers = {}, responseType } = {}) {
		return request({
			method: 'GET',
			url,
			params,
			headers,
			responseType,
		})
	}

	static post({ data = {}, params = {}, url, headers = {}, responseType } = {}) {
		return request({
			method: 'POST',
			url,
			data,
			params,
			headers,
			responseType,
		})
	}

	static put({ data = {}, params = {}, url, headers = {}, responseType } = {}) {
		return request({
			method: 'PUT',
			url,
			data,
			params,
			headers,
			responseType,
		})
	}

	static patch({ data = {}, params = {}, url, headers = {}, responseType } = {}) {
		return request({
			method: 'PATCH',
			url,
			data,
			params,
			headers,
			responseType,
		})
	}

	static delete({ params = {}, url, headers = {}, responseType } = {}) {
		return request({
			method: 'DELETE',
			url,
			params,
			headers,
			responseType,
		})
	}
}

export default Request
