import Request from './request'
import { getCityHeader } from './request'

const SERVER = process.env.REACT_APP_API
const ID_SERVER = process.env.REACT_APP_ID_SERVER
const DATAMAP_API = process.env.REACT_APP_DATAMAP_API

const STRAPI_SERVER = process.env.REACT_APP_STRAPI_SERVER_URL
const STRAPI_SUBSCRIBE_EMAIL = `${STRAPI_SERVER}/subscribe-email`

export function hasUserCompleteInitSurvey(userId = '') {
	return Request.get({
		url: `${SERVER}/v1/hasUserCompleteInitSurvey`,
	})
}

export function getAllUserVotes(userId = '') {
	return Request.get({
		url: `${SERVER}/v1/getAllUserVotes`,
	})
}

export function getEdgesForVoting() {
	return Request.get({
		url: `${SERVER}/v1/getEdgesForVoting`,
	})
}

export function getImagesForVoting() {
	return Request.get({
		url: `${SERVER}/v1/getImagesForVoting`,
	})
}

export function voteEdges(data) {
	return Request.post({
		url: `${SERVER}/v1/voteEdges`,
		data,
	})
}

export function addRouteImages(data) {
	return Request.post({
		url: `${SERVER}/v1/addRouteImages`,
		data,
	})
}

export function updateRouteProps(data) {
	return Request.post({
		url: `${SERVER}/v1/updateRouteProps`,
		data,
	})
}

export function updateImageStreetName(data) {
	return Request.put({
		url: `${SERVER}/v1/updateImageStreetName`,
		data,
	})
}

export function subscribeEmail(email) {
	return Request.put({ data: { email, source: 'velobserver_app' }, url: STRAPI_SUBSCRIBE_EMAIL })
}

export async function login(data) {
	const city = getCityHeader()
	const req = await fetch(`${ID_SERVER}/api/user/login`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			'Client-Api-Key': process.env.REACT_APP_CLIENT_API_KEY,
			city,
		},
	})

	return req.json()
}

export async function signup(data) {
	const city = getCityHeader()
	const req = await fetch(`${ID_SERVER}/api/user/register`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			'Client-Api-Key': process.env.REACT_APP_CLIENT_API_KEY,
			city,
		},
	})

	return req.json()
}

export async function forgotPassword(data) {
	const city = getCityHeader()
	const req = await fetch(`${ID_SERVER}/api/user/init-reset-password`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			'Client-Api-Key': process.env.REACT_APP_CLIENT_API_KEY,
			city,
		},
	})

	return req.json()
}

export async function resetPassword(data) {
	const city = getCityHeader()
	const req = await fetch(`${ID_SERVER}/api/user/reset-password`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			'Client-Api-Key': process.env.REACT_APP_CLIENT_API_KEY,
			city,
		},
	})

	return req.json()
}

export async function getUserDataFromIDServer(jwt) {
	return Request.get({
		url: `${ID_SERVER}/api/user/data`,
		headers: {
			Authorization: jwt,
		},
	})
}

export const routeJourney = async data => {
	try {
		const city = getCityHeader()
		return (
			await Request.post({
				url: `${SERVER}/v1/journey/routing`,
				data,
				headers: {
					city,
				},
			})
		).data
	} catch (e) {
		console.error(e)
		return { type: 'FeatureCollection', features: [] }
	}
}

export const saveUserRoute = async data => {
	try {
		return (
			await Request.post({
				url: `${SERVER}/v1/userRoutes`,
				data,
			})
		).data
	} catch (e) {
		console.error(e)
		return { type: 'FeatureCollection', features: [] }
	}
}

export const getUserRoutes = async () => {
	try {
		return (
			await Request.get({
				url: `${SERVER}/v1/userRoutes`,
			})
		).data
	} catch (e) {
		console.error(e)
		return { type: 'FeatureCollection', features: [] }
	}
}

export const deleteUserRoute = async ({ routeId }) => {
	try {
		return (
			await Request.delete({
				url: `${SERVER}/v1/userRoutes/${routeId}`,
			})
		).data
	} catch (e) {
		console.error(e)
		return { type: 'FeatureCollection', features: [] }
	}
}

export const getPlace = async ({ lat, lon }) => {
	try {
		return (
			await Request.get({
				url: `${DATAMAP_API}/api/posmo/nominatim/reverse/${lat}/${lon}`,
			})
		).data
	} catch (e) {
		console.error(e)
		return {
			type: 'FeatureCollection',
			features: [],
		}
	}
}

export const getUserPlaces = async ({ userId }) => {
	try {
		const userPlaces = (
			await Request.get({
				url: `${DATAMAP_API}/api/posmo/nodes/${userId}`,
			})
		).data

		return {
			type: 'FeatureCollection',
			features: userPlaces.data
				.map(place => {
					return {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [place.lon, place.lat],
						},
						properties: {
							id: place.id,
							name: place.alias_name || place.node_name,
							full_address:
								place.street && place.house_number
									? `${place.street} ${place.house_number}, ${place.postcode ? place.postcode : ''} ${
											place.city ? place.city : ''
									  }, ${place.country ? place.country : ''}`
									: '',
							idle_id: place.idle_id,
						},
					}
				})
				.sort((a, b) => {
					return a.properties.idle_id - b.properties.idle_id
				}),
		}
	} catch (e) {
		console.error(e)
		return {
			type: 'FeatureCollection',
			features: [],
		}
	}
}

export const saveUserPlace = async ({ userId, node }) => {
	try {
		const place = (
			await Request.post({
				url: `${DATAMAP_API}/api/posmo/node`,
				data: { user_id: userId, node },
			})
		).data.data

		return {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [place.lon, place.lat],
					},
					properties: {
						id: place.id,
						name: place.alias_name || place.node_name,
						full_address:
							place.street && place.house_number
								? `${place.street} ${place.house_number}, ${place.postcode ? place.postcode : ''} ${
										place.city ? place.city : ''
								  }, ${place.country ? place.country : ''}`
								: '',
						idle_id: place.idle_id,
					},
				},
			],
		}
	} catch (e) {
		console.error(e)
		return {
			type: 'FeatureCollection',
			features: [],
		}
	}
}

export const getPlaceFromAddress = async ({ address, proximity = 'ip' }) => {
	try {
		const city = getCityHeader()
		const country = city === 'zuerich' || city === 'basel' ? 'ch' : null
		let url = `${DATAMAP_API}/api/search/getPlaceFromAddress?q=${encodeURIComponent(address)}&proximity=${proximity}`
		if (country) {
			url += `&country=${country}`
		}
		return (
			await Request.get({
				url,
			})
		).data
	} catch (e) {
		console.error(e)
		return {
			type: 'FeatureCollection',
			features: [],
		}
	}
}
