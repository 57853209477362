import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import localStorageService from '../../services/localStorageService'
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBikeRounded'
import Icon from '../Icon'
import cx from 'classnames'
import Strings from '../../strings'
import { useParams } from 'react-router-dom'
import { AVAILABLE_PATHS, TOP_LEVEL_DOMAIN } from '../../cities'

import './style.css'

const DesktopMenu = ({ onBack, data, onNewVote }) => {
	const [classification, setClassification] = useState(0)
	const [submitting, setSubmitting] = useState(false)
	let history = useHistory()
	const { state, city } = useParams()

	const getURLPrefix = () => {
		if (!!state) {
			if (!!city) {
				return `/${state}/${city}`
			} else {
				return `/${state}`
			}
		}

		return ''
	}

	const onBikeClicked = () => {
		const urlPrefix = getURLPrefix()
		history.push(urlPrefix)
	}

	const onVotingClicked = () => {
		const urlPrefix = getURLPrefix()
		history.push(urlPrefix + '/general-rating')
	}

	const onMenuClicked = () => {
		const urlPrefix = getURLPrefix()
		history.push(urlPrefix + '/menu')
	}

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search)
		const classification = urlParams.get('criterion')
		setClassification(classification)
	}, [])

	const onNewVotePressed = async mark => {
		if (onNewVote) {
			setSubmitting(true)
			await onNewVote(mark)
			setSubmitting(false)
		}
	}

	const onLogOut = () => {
		localStorageService.removeUser()
		const urlPrefix = getURLPrefix()
		history.push(urlPrefix + '/login')
	}

	const renderNewVotingAction = () => {
		let badVoteLabel = ''
		let bestVoteLabel = ''
		let title = Strings.getMessage('GeneralRatingMap.HowDoYouRate')
		if (classification === 'safety') {
			badVoteLabel = Strings.getMessage('Unsure')
			bestVoteLabel = Strings.getMessage('Secure')
			title = Strings.getMessage('GeneralRatingMap.HowDoYouFeel')
		}

		if (classification === 'conflict') {
			badVoteLabel = Strings.getMessage('LotsOfConflicts')
			bestVoteLabel = Strings.getMessage('NoConflicts')
			title = Strings.getMessage('GeneralRatingMap.CanYouDrive')
		}

		if (classification === 'attractiveness') {
			badVoteLabel = Strings.getMessage('Unattractive')
			bestVoteLabel = Strings.getMessage('Attractive')
			title = Strings.getMessage('GeneralRatingMap.DoYouFind')
		}

		return (
			<div className='DesktopMenu__votingActions'>
				<div>
					<span className='DesktopMenu__segment'>
						{data?.features?.length}
						{data?.features.length > 1 ? ` ${Strings.getMessage('Segments')} ` : ` ${Strings.getMessage('Segment')} `}
					</span>
					<span>{Strings.getMessage('Selected')}.</span>
				</div>
				<div className='DesktopMenu__votingContent'>
					<p className='DesktopMenu__votingText'>{title}</p>
					{!submitting && (
						<>
							<div className='DesktopMenu__votes'>
								<div onClick={onNewVotePressed.bind(null, 1)}>
									<Icon name='rating_1' />
								</div>
								<div onClick={onNewVotePressed.bind(null, 2)}>
									<Icon name='rating_2' />
								</div>
								<div onClick={onNewVotePressed.bind(null, 3)}>
									<Icon name='rating_3' />
								</div>
								<div onClick={onNewVotePressed.bind(null, 4)}>
									<Icon name='rating_4' />
								</div>
							</div>
						</>
					)}
					{submitting && <div className='DesktopMenu__voteSubmitting'>{Strings.getMessage('Submitting')}</div>}
					<div className='DesktopMenu__description'>
						<div className='DesktopMenu__description--bad'>{badVoteLabel}</div>
						<div className='DesktopMenu__description--good'>{bestVoteLabel}</div>
					</div>
				</div>
			</div>
		)
	}

	const renderMenuActions = () => {
		return (
			<div className='DesktopMenu__menuActions'>
				<a
					href={`https://velobserver.${TOP_LEVEL_DOMAIN}`}
					target='_blank'
					rel='noreferrer'
					className='DesktopMenu__menuActionSingleItem DesktopMenu__menuActionSingleItem--middle'>
					<Icon name='home' />
					<div className='Menu__itemText'>VelObserver.{TOP_LEVEL_DOMAIN}</div>
				</a>
				{/* <div className='DesktopMenu__menuActionSingleItem DesktopMenu__menuActionSingleItem--middle'>
					<Icon name='profile' />
					<div className='Menu__itemText'>Profil bearbeiten</div>
				</div> */}
				<div onClick={onLogOut} className='DesktopMenu__menuActionSingleItem'>
					<Icon name='logout' />
					<div className='DesktopMenu__menuItemText'>{Strings.getMessage('Logout')}</div>
				</div>
			</div>
		)
	}

	const path = history?.location?.pathname || '/'
	const isVoting =
		path.includes('/general-rating') ||
		path.includes('/general-rating-photos') ||
		path.includes('/general-rating-map') ||
		path.includes('/classification-rating') ||
		path.includes('/classification-rating-photos') ||
		path.includes('/classification-rating-map')
	const isMenu = path.includes('/menu')

	const bikeColor = path === '/' ? '#5FABE3' : '#707070'
	const bewertenColor = isVoting ? '#5FABE3' : '#707070'
	const menuColor = isMenu ? '#5FABE3' : '#707070'

	const bikeCSS = cx('mainMenu--icon-apply-space MenuIcon', {
		'MenuIcon--active': path === '/' || AVAILABLE_PATHS.includes(path),
	})
	const bewertenCSS = cx('mainMenu--icon-apply-space MenuIcon', { 'MenuIcon--active': isVoting })
	const menuCSS = cx('mainMenu--icon-apply-space MenuIcon', { 'MenuIcon--active': isMenu })

	const featuresLength = data?.features?.length
	const hidePopup = path === '/'
	const showAction = !!featuresLength && !hidePopup

	return (
		<div className='DesktopMenuWrapper'>
			<div className='DesktopMenuContainer'>
				{!onBack && (
					<div className='DesktopMenu__logo'>
						<a href={`https://velobserver.${TOP_LEVEL_DOMAIN}`} target='_blank' rel='noreferrer'>
							<img src='/images/logo.svg' alt='Logo' />
						</a>
					</div>
				)}
				{onBack && (
					<Link className='DesktopMenu__topLink' to={onBack}>
						<Icon name='caret_left' /> <span>{Strings.getMessage('Overview')}</span>
					</Link>
				)}
				<div className='DesktopMenu'>
					<div className={bikeCSS} onClick={onBikeClicked}>
						<div>
							<DirectionsBikeIcon style={{ color: { bikeColor }, fontSize: 26 }} />
						</div>
						<div className='DesktopMenu__label'>{Strings.getMessage('Route')}</div>
					</div>
					<div className={bewertenCSS} onClick={onVotingClicked}>
						<div>
							<Icon name='bewerten' fill={bewertenColor} />
						</div>
						<div className='DesktopMenu__label'>{Strings.getMessage('Evaluete')}</div>
					</div>
					<div className={menuCSS} onClick={onMenuClicked}>
						<div style={{ minHeight: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<Icon name='more' fill={menuColor} />
						</div>
						<div className='DesktopMenu__label'>{Strings.getMessage('More')}</div>
					</div>
				</div>
			</div>
			{showAction && renderNewVotingAction()}
			{isMenu && renderMenuActions()}
		</div>
	)
}

export default DesktopMenu
