import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import MainPage from './pages/MainPage'
import GlobalVoting from './pages/GlobalVoting'
import GlobalVotingPhotos from './pages/GlobalVotingPhotos'
import GlobalVotingMap from './pages/GlobalVotingMap'
import ClassificationVoting from './pages/ClassificationVoting'
import ClassificationVotingPhotos from './pages/ClassificationVotingPhotos'
import ClassificationVotingMap from './pages/ClassificationVotingMap'
import Login from './pages/Login'
import Signup from './pages/Signup'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Menu from './pages/Menu'

export default function Routes() {
	return (
		<Router>
			<Switch>
				<Route path='/:state?/:city?/login'>
					<Login />
				</Route>
				<Route path='/:state?/:city?/login-posmo'>
					<Login posmo />
				</Route>
				<Route path='/:state?/:city?/forgot-password'>
					<ForgotPassword />
				</Route>
				<Route path='/:state?/:city?/reset-password'>
					<ResetPassword />
				</Route>
				<Route path='/:state?/:city?/signup'>
					<Signup />
				</Route>
				<Route path='/:state?/:city?/menu'>
					<Menu />
				</Route>
				<Route path='/:state?/:city?/general-rating'>
					<GlobalVoting />
				</Route>
				<Route path='/:state?/:city?/general-rating-photos'>
					<GlobalVotingPhotos />
				</Route>
				<Route path='/:state?/:city?/general-rating-map'>
					<GlobalVotingMap />
				</Route>
				<Route path='/:state?/:city?/classification-rating'>
					<ClassificationVoting />
				</Route>
				<Route path='/:state?/:city?/classification-rating-photos'>
					<ClassificationVotingPhotos />
				</Route>
				<Route path='/:state?/:city?/classification-rating-map'>
					<ClassificationVotingMap />
				</Route>
				<Route path='/:state?/:city?/dashboard'>
					<Dashboard />
				</Route>
				<Route exact path='/:state?/:city?'>
					<MainPage />
				</Route>
			</Switch>
		</Router>
	)
}

// just testing

function Dashboard() {
	return (
		<div>
			<h2>Dashboard</h2>
		</div>
	)
}
