import english from './english'
import german from './german'

const Strings = (() => {
	let language = 'de'

	return {
		getLanguage: () => {
			return language
		},

		setLanguage: newLanguage => {
			language = newLanguage
		},

		getMessage: messageId => {
			switch (language) {
				case 'de':
					return german[messageId]
				case 'en':
					return english[messageId]
				default:
					return german[messageId]
			}
		},
	}
})()

export default Strings
