import React, { useState } from 'react'
import PlaceSearchResults from '../PlaceSearchResults'
import './style.css'

const popupStyle = {
	backgroundColor: '#fff',
	position: 'absolute',
	top: 160,
	left: 16,
	borderRadius: 20,
	width: 350,
	boxSizing: 'border-box',
	zIndex: 4,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	boxShadow: 'rgba(0, 0, 0, 0.14) 0px 3px 6px',
}

const routeChooserStyle = {
	backgroundColor: '#fff',
	position: 'absolute',
	top: 100,
	left: 16,
	borderRadius: 20,
	width: 320,
	zIndex: 4,
	display: 'flex',
	fontSize: 14,
	padding: 16,
	gap: 16,
	boxShadow: 'rgba(0, 0, 0, 0.14) 0px 3px 6px',
}

export default function MyRoutes(props) {
	const [routeToDelete, setRouteToDelete] = useState(null)
	const [routeName, setRouteName] = useState('')
	const [routeType, setRouteType] = useState('commute_to_eduction')
	const [placeSearchOverviewActive, setPlaceSearchOverviewActive] = useState(false)
	const [mouseOverWaypointInputs, setMouseOverWaypointInputs] = useState(false)
	const [placeSelected, setPlaceSelected] = useState()

	const goToSearch = () => {
		props.onSearchFocus()
		setPlaceSearchOverviewActive(true)
		if (props.searchRef.current) {
			props.searchRef.current.focus()
		}
	}

	const handleDeleteWaypoint = index => {
		props.onDeleteWaypoint(index)
	}

	const renderRouteList = () => {
		if (!props.userRoutes.length) {
			return <div className='routeListItemNotFound'>No routes found</div>
		}

		return props.userRoutes.map(route => {
			return (
				<div
					onClick={() => props.onUserRouteClicked(route.id, !route.selected)}
					className='routeListItem'
					style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					{!(routeToDelete && routeToDelete.id === route.id) ? (
						<div className='myRoutes--routeListItemContainer'>
							<div
								style={{
									borderRadius: '50%',
									marginRight: 4,
									width: 16,
									height: 16,
									backgroundColor: route.color,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								{route.selected ? (
									<svg stroke='#fff' fill='#fff' viewBox='0 0 512 512' height='12px' width='12px'>
										<path d='M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z'></path>
									</svg>
								) : (
									''
								)}
							</div>
							<div className='myRoutes--routeListItemName'>
								<div className='myRoutes--routeName'>{route.name}</div>
								<div className='myRoutes--routeDistance'>- {route.distance} km</div>
							</div>
							<div
								className='myRoutes--routeListItemType'
								onClick={evt => {
									evt.stopPropagation()
									setRouteToDelete(route)
								}}>
								<svg fill='#afafaf' viewBox='0 0 24 24' height='16px' width='16px'>
									<path fill='none' d='M0 0h24v24H0z'></path>
									<path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'></path>
								</svg>
							</div>
						</div>
					) : (
						''
					)}
					{routeToDelete && routeToDelete.id === route.id ? (
						<div className='myRoutes--deleteRouteContainer'>
							<div
								className='myRoutes--routeListItemCancel'
								onClick={evt => {
									evt.stopPropagation()
									setRouteToDelete(null)
								}}>
								Cancel
							</div>
							<div
								className='myRoutes--routeListItemDelete'
								onClick={evt => {
									evt.stopPropagation()
									props.onDeleteRoute(route.id)
								}}>
								Delete
							</div>
						</div>
					) : (
						''
					)}
				</div>
			)
		})
	}

	const renderMyRoutes = () => {
		return (
			<div>
				<div
					style={{
						overflow: 'auto',
						maxHeight: 250,
						fontSize: 14,
						display: 'flex',
						flexDirection: 'column',
						gap: 8,
					}}>
					{renderRouteList()}
				</div>
			</div>
		)
	}

	const renderStartWaypoint = () => {
		const waypointA = {
			enum: 'A',
			name: !props.myRouteStops.start.features[0]
				? 'Startpunkt eingeben'
				: props.myRouteStops.start.features[0]?.properties?.name
				? props.myRouteStops.start.features[0].properties.name
				: 'Waypoint A',
		}
		return (
			<div className='myRoutes--waypointItemWrapper'>
				<div
					className='myRoutes--waypointItem'
					onClick={() => {
						props.startInputFocused.current = true
						goToSearch()
					}}>
					<div className='myRoutes--waypointTitleWrapper'>
						<div className='myRoutes--waypointEnum'>{waypointA.enum}</div>
						<div className='myRoutes--waypointTitle'>{waypointA.name}</div>
					</div>
				</div>
				<div
					onClick={() => {
						handleDeleteWaypoint(0)
					}}
					className='myRoutes--waypointDeleteIcon'
					style={{ width: mouseOverWaypointInputs ? 24 : 0 }}>
					<svg
						fill='#afafaf'
						viewBox='0 0 24 24'
						height={mouseOverWaypointInputs ? 16 : 0}
						width={mouseOverWaypointInputs ? 16 : 0}>
						<path fill='none' d='M0 0h24v24H0z'></path>
						<path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'></path>
					</svg>
				</div>
			</div>
		)
	}

	const renderDestinatonWaypoint = () => {
		const waypointB = {
			enum: 'B',
			name: !props.myRouteStops.destination.features[0]
				? 'Ziel eingeben'
				: props.myRouteStops.destination.features[0]?.properties?.name
				? props.myRouteStops.destination.features[0].properties.name
				: 'Wegpunkt B',
		}
		return (
			<div className='myRoutes--waypointItemWrapper'>
				<div
					className='myRoutes--waypointItem'
					onClick={() => {
						props.startInputFocused.current = false
						goToSearch()
					}}>
					<div className='myRoutes--waypointTitleWrapper'>
						<div className='myRoutes--waypointEnum'>{waypointB.enum}</div>
						<div className='myRoutes--waypointTitle'>{waypointB.name}</div>
					</div>
				</div>
				<div
					onClick={() => {
						handleDeleteWaypoint(props.myRouteStops.viaStops.features.length + 1)
					}}
					className='myRoutes--waypointDeleteIcon'
					style={{ width: mouseOverWaypointInputs ? 24 : 0 }}>
					<svg
						fill='#afafaf'
						viewBox='0 0 24 24'
						height={mouseOverWaypointInputs ? 16 : 0}
						width={mouseOverWaypointInputs ? 16 : 0}>
						<path fill='none' d='M0 0h24v24H0z'></path>
						<path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'></path>
					</svg>
				</div>
			</div>
		)
	}

	const renderMiddleWaypoints = () => {
		return props.myRouteStops.viaStops.features.map((feature, index) => {
			const waypoint = {
				enum: index + 1,
				name: feature.properties?.name ? feature.properties.name : 'Wegpunkt #' + (index + 1),
			}
			return (
				<div className='myRoutes--waypointItemWrapper'>
					<div
						className='myRoutes--waypointItem'
						onClick={() => {
							props.startInputFocused.current = false
							goToSearch()
						}}>
						<div className='myRoutes--waypointTitleWrapper'>
							<div className='myRoutes--waypointEnum'>{waypoint.enum}</div>
							<div className='myRoutes--waypointTitle'>{waypoint.name}</div>
						</div>
					</div>
					<div
						onClick={() => {
							handleDeleteWaypoint(index + 1)
						}}
						className='myRoutes--waypointDeleteIcon'
						style={{ width: mouseOverWaypointInputs ? 24 : 0 }}>
						<svg
							fill='#afafaf'
							viewBox='0 0 24 24'
							height={mouseOverWaypointInputs ? 16 : 0}
							width={mouseOverWaypointInputs ? 16 : 0}>
							<path fill='none' d='M0 0h24v24H0z'></path>
							<path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'></path>
						</svg>
					</div>
				</div>
			)
		})
	}

	const renderWaypoints = () => {
		return (
			<>
				{renderStartWaypoint()}
				{renderMiddleWaypoints()}
				{renderDestinatonWaypoint()}
			</>
		)
	}

	const renderRouting = () => {
		return (
			<>
				<div className='myRoutes--routingWrapper'>
					<div>
						<div className='myRoutes--configItem'>
							<div className='myRoutes--activityWrapper'>
								<svg
									class='MuiSvgIcon-root'
									focusable='false'
									viewBox='0 0 24 24'
									aria-hidden='true'
									style={{ fontSize: 18 }}>
									<path d='M15.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM5 12c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5zm5.8-10l2.4-2.4.8.8c1.06 1.06 2.38 1.78 3.96 2.02.6.09 1.14-.39 1.14-1 0-.49-.37-.91-.85-.99-1.11-.18-2.02-.71-2.75-1.43l-1.9-1.9c-.5-.4-1-.6-1.6-.6s-1.1.2-1.4.6L7.8 8.4c-.4.4-.6.9-.6 1.4 0 .6.2 1.1.6 1.4L11 14v4c0 .55.45 1 1 1s1-.45 1-1v-4.4c0-.52-.2-1.01-.55-1.38L10.8 10.5zM19 12c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5z'></path>
								</svg>
								<div>Aktivität</div>
							</div>
							<div className='myRoutes--selectActivity'>
								<select
									style={{ maxWidth: 220 }}
									onChange={evt => {
										setRouteType(evt.target.value)
									}}>
									<option value='commute_to_eduction'>Fahrt zur Bildung</option>
									<option value='commute_to_work'>Fahrt zur Arbeit</option>
									<option value='commute_to_nursery_preschool_school'>Fahrt zur Kita/Vorschule/Schule</option>
									<option value='business_trip'>Geschäftsreise</option>
									<option value='transportation_as_occupation'>Transport als Beruf</option>
									<option value='cafe_restaurant'>Café / Restaurant</option>
									<option value='shopping_daily_products'>Einkauf (Alltagsprodukte)</option>
									<option value='shopping_nondaily_products'>Einkauf (nicht-Alltagsprodukte)</option>
									<option value='medical_service'>Medizinischer Dienst</option>
									<option value='other_service'>Andere Dienstleistung</option>
									<option value='social_visit'>Gesellschaftlicher Besuch</option>
									<option value='chauffeuring_person'>Personenkraftfahrt</option>
									<option value='sport_recreation'>Sport / Erholung</option>
									<option value='culture'>Kultur</option>
									<option value='other_education'>Andere Bildung</option>
									<option value='other_leisure'>Andere Freizeit</option>
								</select>
							</div>
						</div>
						<div
							className='myRoutes--waypointsWrapper'
							onMouseEnter={() => {
								setMouseOverWaypointInputs(true)
							}}
							onMouseLeave={() => {
								setMouseOverWaypointInputs(false)
							}}>
							{renderWaypoints()}
						</div>
					</div>
				</div>
				<div className='myRoute--saveRoutesWrapper'>
					<div className='myRoute--infoOnRouting'>
						Bevor Sie Ihre Route speichern, müssen Sie sie so genau wie möglich kartieren. Sie können damit beginnen,
						indem Sie auf eine Karte klicken oder die Adresse in der Suchleiste finden.
					</div>
					<div>
						<input
							className='myRoutes--input'
							style={{ width: '100%' }}
							type='text'
							value={routeName}
							onChange={e => setRouteName(e.target.value)}
							placeholder='Geben Sie den Routennamen ein'
						/>
					</div>
					<button
						disabled={!routeName.length || props.journeyFeatureCollection.features.length === 0}
						className='myRoutes--button'
						onClick={() => {
							props.onSaveRoute({
								name: routeName,
								type: routeType,
							})
							setRouteName('')
						}}>
						Neue Route erstellen
					</button>
				</div>
			</>
		)
	}

	const renderPlaceSearchOverview = () => {
		return (
			<div>
				<div className='myRoutes--searchResultsTitle'>
					{!props.search.text.length ? 'Gespeicherte Orte' : 'Suchergebnisse'}
				</div>
				<PlaceSearchResults
					placeSelected={placeSelected}
					setPlaceSelected={setPlaceSelected}
					startInputFocused={props.startInputFocused}
					onPlaceClicked={feature => {
						props.onPlaceClicked(feature)
					}}
					onPlaceEditorClose={props.onPlaceEditorClose}
					placeList={props.searchResults}
					onSavePlace={props.onSavePlace}
					myRouteStops={props.myRouteStops}
					onSetAsStartPoint={feature => {
						props.setSearch({ text: '' })
						setPlaceSelected(null)
						props.onSetAsStartPoint(feature)
						setPlaceSearchOverviewActive(false)
					}}
					onSetAsEndPoint={feature => {
						props.setSearch({ text: '' })
						setPlaceSelected(null)
						props.onSetAsEndPoint(feature)
						setPlaceSearchOverviewActive(false)
					}}
					onSetAsViaPoint={feature => {
						props.setSearch({ text: '' })
						setPlaceSelected(null)
						props.onSetAsViaPoint(feature)
						setPlaceSearchOverviewActive(false)
					}}
				/>
			</div>
		)
	}

	const renderCreateRoutes = () => {
		return (
			<>
				<div className='myRoutes--searchWrapper'>
					<div
						className='myRoutes--searchClose'
						style={{
							cursor: 'pointer',
							visibility: placeSearchOverviewActive ? 'visible' : 'hidden',
							width: placeSearchOverviewActive ? '24px' : 0,
						}}
						onClick={() => {
							setPlaceSearchOverviewActive(false)
							props.onSearchClose()
						}}>
						<svg
							stroke='currentColor'
							fill='currentColor'
							stroke-width='0'
							viewBox='0 0 512 512'
							height='24px'
							width='24px'>
							<path d='M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z'></path>
						</svg>
					</div>
					<input
						onFocus={() => {
							props.onSearchFocus()
							setPlaceSelected(null)
						}}
						value={props.search.text}
						onChange={e => {
							setPlaceSelected(null)
							props.onSearchChange(e.target.value)
						}}
						onClick={() => setPlaceSearchOverviewActive(true)}
						style={{ width: '100%' }}
						type='text'
						placeholder='Nach Straßenname, Straßenummer suchen'
						className='myRoutes--input'
						ref={props.searchRef}
					/>
				</div>
				{placeSearchOverviewActive ? renderPlaceSearchOverview() : ''}
				{!placeSearchOverviewActive ? renderRouting() : ''}
			</>
		)
	}

	return (
		<div>
			<div style={routeChooserStyle}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<input
						style={{ margin: '0 4px 0 0' }}
						type='radio'
						id='routeChooserOfficialRoutes'
						name='routeChooser'
						value='officialRoutes'
						onClick={() => props.onSetRouteTypeChooser('officialRoutes')}
						checked={props.routeTypeChooser === 'officialRoutes' ? true : false}
					/>
					<label for='officialRoutes'>Offizielle Routen</label>
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<input
						style={{ margin: '0 4px 0 0' }}
						type='radio'
						id='routeChooserMyRoutes'
						name='routeChooser'
						value='myRoutes'
						onClick={() => props.onSetRouteTypeChooser('myRoutes')}
						checked={props.routeTypeChooser === 'myRoutes' ? true : false}
					/>
					<label for='myRoutes'>Meine Routen</label>
				</div>
			</div>
			{props.myRoutesOpened && props.routeTypeChooser === 'myRoutes' ? (
				<div style={popupStyle}>
					<div className={'myRoutes--TabWrapper'}>
						<div
							onClick={() => props.onSetSelectedTab('routeList')}
							className={props.selectedTab === 'routeList' ? 'left active' : 'left'}>
							Übersicht
						</div>
						<div
							onClick={() => props.onSetSelectedTab('createRoute')}
							className={props.selectedTab === 'createRoute' ? 'right active' : 'right'}>
							Route erstellen
						</div>
					</div>
					<div className='myRoutes--contentWrapper'>
						{props.selectedTab === 'routeList' ? renderMyRoutes() : ''}
						{props.selectedTab === 'createRoute' ? renderCreateRoutes() : ''}
					</div>
				</div>
			) : null}
		</div>
	)
}
