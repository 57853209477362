import React, { Component } from "react";
import BikeIndex from "../BikeIndex";
import './style.css'

class MainPage extends Component {

    render() {
        return <BikeIndex />
    }
}

export default MainPage;
