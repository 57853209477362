import * as React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { styled } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import axios from 'axios'
import Strings from '../strings'

const CssTextField = styled(TextField)({
	'& .MuiFormLabel-root': {
		fontSize: 12,
	},
	'& label.Mui-focused': {
		color: '#2d2047',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: '#2d2047',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#2d2047',
		},
		'&:hover fieldset': {
			borderColor: '#2d2047',
		},
		'&.Mui-focused': {
			borderColor: '#2d2047',
		},
	},
})

const CssSelect = styled(Select)({
	'& legend.Mui-focused': {
		color: '#2d2047',
	},
	'& .MuiInput-underline': {
		borderColor: '#2d2047',
	},
	'&:after': {
		borderBottom: '2px solid #2d2047',
	},
	'& fieldset': {
		borderColor: '#2d2047',
	},
	'&:hover fieldset': {
		borderColor: '#2d2047',
	},
	'&.Mui-focused fieldset': {
		borderColor: '#2d2047',
	},
})

const defaultCountry = 'Schweiz'

export default function PersonalData(props) {
	const [state, setState] = React.useState(props.data)

	React.useEffect(() => {
		chechUserCountry()
	}, [])

	const chechUserCountry = async () => {
		fetch('https://extreme-ip-lookup.com/json/?key=R6rvbTSxEW2TbOHSjUtt')
			.then(res => res.json())
			.then(response => {
				let country = response.country
				if (country === 'Switzerland') {
					country = 'Schweiz'
				}

				setState({ ...state, country })
				props.onChange({ ...state, country })
			})
			.catch((data, status) => {
				setState({ ...state, country: defaultCountry })
				props.onChange({ ...state, country: defaultCountry })
			})
	}

	return (
		<Box style={{ marginLeft: 0 }}>
			<FormControl style={{ marginTop: 24 }} component='fieldset'>
				<FormLabel style={{ marginBottom: 8, color: '#444', fontFamily: 'DM Sans', fontSize: 14 }} component='legend'>
					{Strings.getMessage('Survey.PersonalInfo.Gender')}
				</FormLabel>
				<RadioGroup
					style={{ flexWrap: 'nowrap', fontSize: 12 }}
					onChange={e => {
						setState({ ...state, gender: e.target.value })
						props.onChange({ ...state, gender: e.target.value })
					}}
					row
					aria-label='gender'
					name='row-radio-buttons-group'>
					<FormControlLabel
						checked={state.gender === 'female' ? true : false}
						value='female'
						control={<Radio size={'small'} style={{ color: '#2d2047' }} />}
						label={
							<Typography style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
								{Strings.getMessage('Survey.PersonalInfo.Femail')}
							</Typography>
						}
					/>
					<FormControlLabel
						checked={state.gender === 'male' ? true : false}
						value='male'
						control={<Radio size={'small'} style={{ color: '#2d2047' }} />}
						label={
							<Typography style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
								{Strings.getMessage('Survey.PersonalInfo.Male')}
							</Typography>
						}
					/>
					<FormControlLabel
						checked={state.gender === 'other' ? true : false}
						value='other'
						control={<Radio size={'small'} style={{ color: '#2d2047' }} />}
						label={
							<Typography style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
								{Strings.getMessage('Survey.PersonalInfo.Diverse')}
							</Typography>
						}
					/>
				</RadioGroup>
				<h3 style={{ fontSize: 14, fontWeight: 300, color: '#444', fontFamily: 'DM Sans', marginTop: 24, padding: 0 }}>
					{Strings.getMessage('Survey.PersonalInfo.Alter')}
				</h3>
				<CssTextField
					onChange={e => {
						setState({ ...state, birthyear: e.target.value })
						props.onChange({ ...state, birthyear: e.target.value })
					}}
					style={{ width: 250 }}
					value={state.birthyear}
					label={Strings.getMessage('Survey.PersonalInfo.YearPlaceholder')}
					labelid='age-select'
					id='age-select'
				/>
			</FormControl>
			<Box style={{ marginTop: 24 }}>
				<h3 style={{ fontSize: 16, fontWeight: 300, color: '#444', fontFamily: 'DM Sans', marginBottom: 12, padding: 0 }}>
					{Strings.getMessage('Survey.PersonalInfo.Residence')}
				</h3>
				<Box>
					<CssTextField
						value={state.postcode}
						onChange={e => {
							setState({ ...state, postcode: e.target.value })
							props.onChange({ ...state, postcode: e.target.value })
						}}
						style={{ marginRight: 12, width: 50 }}
						id='postcode'
						label={Strings.getMessage('Survey.PersonalInfo.PLZ')}
						variant='standard'
					/>
					<CssTextField
						value={state.city}
						onChange={e => {
							setState({ ...state, city: e.target.value })
							props.onChange({ ...state, city: e.target.value })
						}}
						style={{ width: 186 }}
						id='city'
						label={Strings.getMessage('Survey.PersonalInfo.Location')}
						variant='standard'
					/>
				</Box>
				<Box style={{ marginTop: 24, display: 'block' }}>
					<FormControl component='fieldset' variant='standard'>
						<CssTextField
							defaultValue={defaultCountry}
							value={state.country}
							onChange={e => {
								setState({ ...state, country: e.target.value })
								props.onChange({ ...state, country: e.target.value })
							}}
							style={{ width: 250 }}
							id='country'
							label={Strings.getMessage('Survey.PersonalInfo.Country')}
							variant='standard'
						/>
					</FormControl>
				</Box>
			</Box>
		</Box>
	)
}
