import axios from 'axios'
import { getCityHeader } from './request'

export default async function getVeloplanEdges(city) {
	const headerCity = getCityHeader()
	if (city === 'muenchen') {
		return await axios.get(`/map/munich.geojson`, { headers: { headerCity } })
	}
	if (city === 'basel') {
		return await axios.get('/map/basel_routes_2024.geojson', { headers: { headerCity } })
	}

	return await axios.get(`/map/zurich_vorzugsrouten_2023.geojson`, { headers: { headerCity } })
}
