import mapboxgl from 'mapbox-gl'
import { WebMercatorViewport } from '@math.gl/web-mercator'
import { nearestPointOnLine } from '@turf/nearest-point-on-line'
import chroma from 'chroma-js'
import React, { useState, useRef, useEffect } from 'react'
import MapGL, { MapContext, Popup, GeolocateControl, Source, Layer } from 'react-map-gl'
import { useAppContext } from '../components/UserContext'
import 'mapbox-gl/dist/mapbox-gl.css'
import RouteEditorMenu from './RouteEditorMenu'
import MainMenu from './MainMenu'
import classifyEdges from '../api/classifyEdges'
import addRouteEdges from '../api/addRouteEdges'
import updateEdgesStatus from '../api/updateEdgesStatus'
import localStorageService from '../services/localStorageService'
import DesktopMenu from '../components/DesktopMenu'
import MyRoutes from './MyRoutes'
import { getCityHeader } from '../api/request'
import {
	routeJourney,
	getPlaceFromAddress,
	getUserPlaces,
	saveUserPlace,
	saveUserRoute,
	getUserRoutes,
	deleteUserRoute,
	getPlace,
} from '../api'
import debounce from 'lodash.debounce'
import { distance } from '@turf/distance'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'
import { AVAILABLE_CITIES } from '../cities'

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default
const TOKEN = 'pk.eyJ1IjoiZGpyYSIsImEiOiJjamZmZ2RzamYyM2JyMzNwYWc1aThzdmloIn0.tuEuIrtp3DK0ALX2J1clEw'

const MapStyles = {
	DEFAULT: 'mapbox://styles/djra/ckx90pkh58k5d15p5066x6x7r',
	SATELLITE: 'mapbox://styles/mapbox/satellite-v9',
	ROUTING: 'mapbox://styles/djra/cm0nsr8yb00dp01pjfljm7u2n',
}

const generateColors = numColors => {
	var colors = []

	for (var i = 0; i < numColors; i++) {
		var randomColor = chroma.random()
		var contrastRatio = chroma.contrast(randomColor, '#ffffff')

		// Adjust the contrast ratio threshold as needed (4.5 is recommended for text readability)
		while (contrastRatio < 4.5) {
			randomColor = chroma.random()
			contrastRatio = chroma.contrast(randomColor, '#ffffff')
		}

		// Convert the color to hex format and add to the array
		var hexColor = randomColor.hex()
		colors.push(hexColor)
	}

	return colors
}

const generateDarkerColor = color => {
	return chroma(color).darken(0.5).hex()
}

const SwitchLayer = ({ mapStyle, setMapStyle, mapRef }) => {
	if (!mapRef.current) {
		return
	}

	const map = mapRef.current.getMap()

	if (typeof map.moveLayer === 'function') {
		map.moveLayer('pin-connections-layer', 'images-pins-origin-layer')
		map.moveLayer('pin-connections-layer', 'images-pins-layer')
	}

	const handleMapLayerChange = () => {
		if (map) {
			if (mapStyle === MapStyles.DEFAULT) {
				setMapStyle(MapStyles.SATELLITE)
			} else {
				setMapStyle(MapStyles.DEFAULT)
			}
		}
	}

	return (
		<div
			style={{
				position: 'absolute',
				border: '2px solid #fff',
				borderRadius: 4,
				zIndex: 5,
				left: 10,
				bottom: 25,
				width: 80,
				height: 60,
			}}
			onClick={() => handleMapLayerChange()}>
			<div style={{ position: 'relative' }}>
				<img
					alt='switch satellite / map view'
					src={mapStyle === 'mapbox://styles/mapbox/satellite-v9' ? 'assets/map.png' : 'assets/satellite.png'}
					width={80}
					height={60}
				/>
				<div
					style={{
						position: 'absolute',
						bottom: 8,
						left: 8,
						color: mapStyle === 'mapbox://styles/mapbox/satellite-v9' ? '#777' : '#fff',
						fontSize: 14,
					}}>
					{mapStyle === 'mapbox://styles/mapbox/satellite-v9' ? 'Map' : 'Satellite'}
				</div>
			</div>
		</div>
	)
}

// Wrapper for MapGL component so that viewport changes (zooming and panning) do not trigger
// expensive rerender of everything else inside Map component, e.g. list of edges in RouteEditorMenu
// TODO: Upgrade react-map-gl and use uncontrolled map, no need to track viewport state
// https://visgl.github.io/react-map-gl/docs/get-started/state-management#uncontrolled-map
const InternalMap = ({ viewport, children, ...props }) => {
	const [internalViewport, setInternalViewport] = useState({})
	useEffect(() => setInternalViewport({ ...viewport }), [viewport])

	return React.cloneElement(children, {
		...internalViewport,
		...props,
		onViewportChange: viewport => setInternalViewport({ ...viewport }),
	})
}

const Map = props => {
	const ctx = useAppContext()
	const [viewport, setViewPort] = useState({
		latitude: 47.3769,
		longitude: 8.5417,
		zoom: 12,
		minZoom: 9,
	})
	const mapRef = useRef()
	const [popup, setPopup] = React.useState(null)
	const [geoLocateControlStyle, setGeoLocateStyle] = useState({
		right: 6,
		top: 20,
		position: 'fixed',
	})
	const [myRoutesOpened, setMyRouteOpened] = useState(true)
	const [isJourneyLoading, setIsJourneyLoading] = useState(false)
	const query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
	const [routeTypeChooser, setRouteTypeChooser] = React.useState(query.placeName ? 'myRoutes' : 'officialRoutes')
	const [routeConnections, setRouteConnections] = React.useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [myRouteStops, setMyRouteStops] = useState({
		start: {
			type: 'FeatureCollection',
			features: [],
		},
		destination: {
			type: 'FeatureCollection',
			features: [],
		},
		viaStops: {
			type: 'FeatureCollection',
			features: [],
		},
	})
	const searchRef = useRef()
	const startInputFocused = useRef(false)
	const [mySelectedPlace, setMySelectedPlace] = useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [userPlaces, setUserPlaces] = useState({ type: 'FeatureCollection', features: [] })
	const [search, setSearch] = useState({ text: '' })
	const [searchResults, setSearchResults] = useState({ type: 'FeatureCollection', features: [] })
	const [userRoutes, setUserRoutes] = useState([])
	const [journeyFeatureCollection, setJourneyFeatureCollection] = useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [journeyLineString, setJourneyLineString] = useState({
		type: 'Feature',
		geometry: {
			type: 'LineString',
			coordinates: [],
		},
	})
	const [showInfoPopup, setShowInfoPopup] = useState({
		type: 'Feature',
		geometry: {
			type: 'Point',
			coordinates: [],
		},
	})
	const [mapStyle, setMapStyle] = useState(MapStyles.DEFAULT)
	const [selectedTab, setSelectedTab] = useState('routeList')
	const [journeyTracker, setJourneyTracker] = React.useState({
		fc: {
			type: 'FeatureCollection',
			features: [],
		},
		visible: false,
	})
	const journeyTrackerDragging = useRef({ dragging: false, existingPoint: null })

	useEffect(() => {
		if (mapRef?.current) {
			const city = getCityHeader()
			const cityCoordinates = AVAILABLE_CITIES[city]
			if (cityCoordinates) {
				setViewPort(cityCoordinates)
			}
		}
	}, [mapRef?.current])

	useEffect(() => {
		if (routeTypeChooser === 'myRoutes') {
			setMapStyle(MapStyles.ROUTING)
			const userId = localStorageService.getUser()?.user_id
			getUserPlaces({ userId }).then(places => {
				setUserPlaces(places)
			})

			getUserRoutes().then(routes => {
				routes.forEach(route => {
					route.outlineColor = generateDarkerColor(route.color)
					route.selected = true
				})

				setUserRoutes(routes)
			})

			const query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
			if (query.placeName && query.placeLonLat) {
				setSelectedTab('createRoute')
				setMyRouteStops({
					destination: {
						type: 'FeatureCollection',
						features: [
							{
								type: 'Feature',
								geometry: {
									type: 'Point',
									coordinates: query.placeLonLat.split(',').map(parseFloat),
								},
								properties: {
									name: query.placeName,
									enum: 'B',
								},
							},
						],
					},
					start: {
						type: 'FeatureCollection',
						features: [],
					},
					viaStops: {
						type: 'FeatureCollection',
						features: [],
					},
				})
			}
		} else {
			setMapStyle(MapStyles.DEFAULT)
		}
	}, [routeTypeChooser])

	const handleOnCommit = mapping => {
		addRouteEdges(mapping)
			.then(data => data.data)
			.then(data => {
				props.updateData(data)
			})
			.catch(err => {
				console.log(err)
			})
	}

	const handleMappingEdgesStatus = async edges => {
		try {
			const data = (await updateEdgesStatus(edges)).data
			props.updateData(data)
			setSelectedFeatures({ type: 'FeatureCollection', features: [] })
		} catch (err) {
			console.log(err)
		}
	}

	const getClassificationColor = classification => {
		let votes = 0
		let votesNumber = 0

		if (classification.safety && classification.attractiveness && classification.conflict) {
			const detailedVoting = (classification.safety + classification.attractiveness + classification.conflict) / 3
			votes += detailedVoting
			votesNumber++
		}

		// only calculate real values
		if (classification.globalVote) {
			votes += classification.globalVote
			votesNumber++
		}

		const value = votes / votesNumber

		if (votesNumber === 0) {
			return '#B3ACBD'
		}

		if (value < 1.5) {
			return '#ec6d6e'
		} else if (value >= 1.5 && value < 2.5) {
			return '#f3b442'
		} else if (value >= 2.5 && value < 3.5) {
			return '#96b63c'
		} else if (value >= 3.5) {
			return '#59864e'
		}
	}

	const handleClassify = classification => {
		classifyEdges({
			type: 'FeatureCollection',
			features: selectedFeatures.features,
			properties: {
				user_id: localStorageService.getUser()?.user_id,
				...classification,
			},
		})
			.then(data => data)
			.then(() => {
				// in user classification view only thing that changed are edges sent to be reclassified.
				const newClassifiedFeaturesIds = []
				const newClassifiedFeatures = selectedFeatures.features.map(feature => {
					feature.properties.color = getClassificationColor(classification)
					newClassifiedFeaturesIds.push(feature.properties.ogc_fid)
					return feature
				})

				// filter old classifications
				const filteredClassifiedFeatures = classifiedFeatures.features.filter(feature => {
					return !newClassifiedFeaturesIds.includes(feature.properties.ogc_fid)
				})

				setClassifiedFeatures({
					type: 'FeatureCollection',
					features: [...filteredClassifiedFeatures, ...newClassifiedFeatures],
				})

				setTimeout(() => {
					setSelectedFeatures({
						type: 'FeatureCollection',
						features: [],
					})
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	const roundCoordinates = coordinates => {
		return [Math.round(coordinates[0] * 1000000) / 1000000, Math.round(coordinates[1] * 1000000) / 1000000]
	}

	// Allow highlighting edges by clicking them in RouteEditorMenu
	const [clickedOgcFid, setClickedOgcFid] = useState(null)
	const [highlightedOgcFid, setHighlightedOgcFid] = useState(null)
	const [highlightedFeatures, setHighlightedFeatures] = useState({ type: 'FeatureCollection', properties: {}, features: [] })
	useEffect(() => {
		let timeoutId
		const feature = highlightedOgcFid && props.data.features.find(feature => feature.properties.ogc_fid === highlightedOgcFid)
		if (feature) {
			// Update highlighted feature and show it
			setHighlightedFeatures({ type: 'FeatureCollection', properties: {}, features: [feature] })
			mapRef.current.getMap().setPaintProperty('feature-highlighted-layer', 'line-opacity', 1)

			// Hide highlight after 2 seconds
			timeoutId = setTimeout(() => {
				mapRef.current.getMap().setPaintProperty('feature-highlighted-layer', 'line-opacity', 0)
				setHighlightedOgcFid(null)
			}, 2000)
		}

		// Clear hiding timeout if highlightedOgcFid changes or component unmounts
		return () => {
			timeoutId && clearTimeout(timeoutId)
		}
	}, [highlightedOgcFid])

	const handleClassificationChange = classification => {
		const classificationColor = getClassificationColor(classification)

		const features = selectedFeatures.features.map(feature => {
			return { ...feature, properties: { ...feature.properties, color: classificationColor } }
		})

		setSelectedFeatures({
			type: 'FeatureCollection',
			features,
		})
	}
	const [selectedFeatures, setSelectedFeatures] = useState({ type: 'FeatureCollection', properties: {}, features: [] })
	const [classifiedFeatures, setClassifiedFeatures] = useState({
		type: 'FeatureCollection',
		properties: {},
		features: (() => {
			return props.classifiedData.features.map(f => {
				f.properties.color = getClassificationColor(f.properties)
				return f
			})
		})(),
	})
	const [routeMenuOpened, setRouteMenuOpened] = useState(false)
	const [activeMenu, setActiveMenu] = useState({ title: 'classify' })
	const [veloData, setVeloData] = React.useState()
	const [adminOption, setAdminOption] = React.useState('route_builder')
	const [veloCityData, setVeloCityData] = React.useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [selectedRouteData, setSelectedRouteData] = useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [selectedOfficalRoute, setSelectedOfficalRoute] = useState({
		type: 'FeatureCollection',
		features: [],
	})

	const setRouteId = routeId => {
		setSelectedRouteData({
			type: 'FeatureCollection',
			features: props.data.features.filter(feature => {
				return feature.properties.route_id === routeId
			}),
		})
	}

	const [freezePointFeature, setFreezePointFeature] = useState({
		type: 'FeatureCollection',
		features: [],
	})
	const currentCoordiantes = useRef({ latitude: 0, longitude: 0 })

	const removeStart = () => {
		journeyStateCleanup(true)
	}

	const journeyStateCleanup = includingStops => {
		setJourneyFeatureCollection({
			type: 'FeatureCollection',
			features: [],
		})
		setJourneyLineString({
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: [],
			},
		})
		setRouteConnections({
			type: 'FeatureCollection',
			features: [],
		})
		setJourneyTracker({ fc: { type: 'FeatureCollection', features: [] }, visible: false })
		setShowInfoPopup({ type: 'Feature', geometry: { type: 'Point', coordinates: [] } })
		if (includingStops) {
			setMyRouteStops({
				start: {
					type: 'FeatureCollection',
					features: [],
				},
				destination: {
					type: 'FeatureCollection',
					features: [],
				},
				viaStops: {
					type: 'FeatureCollection',
					features: [],
				},
			})

			setSearchResults({ type: 'FeatureCollection', features: [] })
			setSearch({ text: '', active: false })
		}
	}

	function getCenterFromBounds(bounds) {
		const centerLng = (bounds[0][0] + bounds[1][0]) / 2
		const centerLat = (bounds[0][1] + bounds[1][1]) / 2
		return [centerLng, centerLat]
	}

	function getBoundsFromCenter(center) {
		const zoom = 14
		const [longitude, latitude] = center
		const halfWidth = 360 / Math.pow(2, zoom) / 2
		const halfHeight = 180 / Math.pow(2, zoom) / 2
		const bounds = {
			west: longitude - halfWidth,
			east: longitude + halfWidth,
			south: latitude - halfHeight,
			north: latitude + halfHeight,
		}

		return [
			[bounds.east, bounds.south],
			[bounds.west, bounds.north],
		]
	}

	React.useEffect(() => {
		const features = props.data.features.filter(f => {
			return f.properties.route_id && f.properties.route_visible === 1
		})
		setVeloData({
			type: 'FeatureCollection',
			features,
		})

		// pan map to city coordinates
		if (mapRef && mapRef.current && props.city) {
			// mapRef.current.getMap().fitBounds(props.city.bbox);

			const zoom = mapRef.current.getMap().getZoom()
			const center = getCenterFromBounds(props.city.bbox)

			setViewPort({
				latitude: center[1],
				longitude: center[0],
				zoom,
			})
		}
	}, [props.city])

	const handleFreezePointSet = point => {
		setFreezePointFeature({
			type: 'Feature',
			properties: {},
			...point,
		})
	}

	const imagesPinsFeatures = React.useMemo(
		() => ({
			type: 'FeatureCollection',
			features:
				props.images &&
				props.images.features.map(feature => {
					return {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [feature.properties.pin_lon, feature.properties.pin_lat],
						},
						properties: { ...feature.properties },
					}
				}),
		}),
		[props.images],
	)

	const imagesPinsOriginFeatures = React.useMemo(
		() => ({
			type: 'FeatureCollection',
			features:
				props.images &&
				props.images.features.map(feature => {
					return {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [feature.properties.exif_lon, feature.properties.exif_lat],
						},
						properties: { ...feature.properties },
					}
				}),
		}),
		[props.images],
	)

	const pinConnectionsFeatures = React.useMemo(
		() => ({
			type: 'FeatureCollection',
			features:
				props.images &&
				props.images.features.map(feature => {
					return {
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: [
								[feature.properties.exif_lon, feature.properties.exif_lat],
								[feature.properties.pin_lon, feature.properties.pin_lat],
							],
						},
						properties: { ...feature.properties },
					}
				}),
		}),
		[props.images],
	)

	const officialRouteLayer = {
		id: 'official-route-layer',
		type: 'line',
		source: 'official_route',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': ['get', 'color'],
			'line-width': 4,
			'line-opacity': 0.4,
		},
	}

	const userRoutesLayer = {
		id: 'userRoutesLayer',
		type: 'line',
		source: 'userRoutes',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': ['get', 'color'],
			'line-width': 3,
		},
	}

	const userRoutesOutlineLayer = {
		id: 'userRoutesOutlineLayer',
		type: 'line',
		source: 'userRoutes',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': ['get', 'outlineColor'],
			'line-width': 5,
		},
	}

	const veloplanLayer = {
		id: 'veloplan-layer',
		type: 'line',
		source: 'veloplan',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': ['get', 'color'],
			'line-width': ['get', 'line_width'],
			'line-opacity': 0.6,
		},
	}

	const selectedRouteLayer = {
		id: 'selectedRoute-layer',
		type: 'line',
		source: 'selectedRoute',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			// Paint expired edges as red, otherwise black
			// https://docs.mapbox.com/style-spec/reference/expressions/
			'line-color': ['case', ['to-boolean', ['get', 'expired_at']], '#f50057', '#000'],
			// Paint expired edges slightly transparent so user can see new edges underneath
			'line-opacity': ['case', ['to-boolean', ['get', 'expired_at']], 0.5, 1],
			'line-width': 4,
		},
	}

	const networkLayer = {
		id: 'network-layer',
		type: 'line',
		source: 'network',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': ['get', 'color'],
			'line-width': ['get', 'line_width'],
		},
	}

	const journeyLayer = {
		id: 'journey-layer',
		type: 'line',
		source: 'journey',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': ['get', 'color'],
			'line-width': 3,
		},
	}

	const journeyLoadingLayer = {
		id: 'journey-loading-layer',
		type: 'line',
		source: 'journey',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': 'orange',
			'line-width': 3,
			'line-opacity': 0.5,
		},
	}

	const journeyLayerOutline = {
		id: 'journey-layer-outline',
		type: 'line',
		source: 'journey',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': '#1a216e',
			'line-width': 5,
		},
	}

	const featureHighlightedLayer = {
		id: 'feature-highlighted-layer',
		type: 'line',
		layout: {
			'line-cap': 'round',
		},
		paint: {
			'line-color': '#ffaa00',
			'line-width': 6,
			'line-opacity': 0,
		},
	}

	const featureSelectedLayer = {
		id: 'feature-selected-layer',
		type: 'line',
		source: 'feature-selected',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': ['get', 'color'],
			'line-gap-width': 0,
			'line-width': 4,
		},
	}

	const featureSelectedLayerBorder = {
		id: 'feature-selected-layer-border',
		type: 'line',
		source: 'feature-selected',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': '#fff',
			'line-gap-width': 0,
			'line-width': 8,
		},
	}

	const classifiedFeaturesLayer = {
		id: 'classified-features-layer',
		type: 'line',
		source: 'classified-features',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': ['get', 'color'],
			'line-width': 4,
		},
	}
	const freezePointLayer = {
		id: 'freeze-point-layer',
		type: 'circle',
		source: 'freeze-point',
		paint: {
			'circle-radius': {
				base: 1.95,
				stops: [
					[12, 2],
					[22, 180],
				],
			},
			'circle-color': 'red',
			'circle-opacity': 1,
			'circle-stroke-width': 2,
			'circle-stroke-color': '#fff',
		},
	}

	const journeyTrackerLayer = {
		id: 'journey-tracker-layer',
		type: 'circle',
		source: 'journey',
		paint: {
			'circle-radius': {
				base: 2.2,
				stops: [[12, 3]],
			},
			'circle-color': '#fff',
			'circle-opacity': 1,
			'circle-stroke-width': 5,
			'circle-stroke-color': '#777',
			'circle-stroke-opacity': 0.3,
		},
	}

	const myRoutesRoutingConnections = {
		id: 'my-route-routing-connections-layer',
		type: 'line',
		source: 'my-routes-routing-connections',
		layout: {
			'line-join': 'bevel',
			'line-cap': 'butt',
		},
		paint: {
			'line-color': '#2a2a2a',
			'line-width': 4,
			'line-dasharray': [1, 2, 1],
		},
	}

	const myRoutesUserRoutingConnections = {
		id: 'my-routes-user-routing-connections-layer',
		type: 'line',
		source: 'my-user-routes-routing-connections',
		layout: {
			'line-join': 'bevel',
			'line-cap': 'butt',
		},
		paint: {
			'line-color': '#2a2a2a',
			'line-width': 4,
			'line-dasharray': [1, 2, 1],
		},
	}

	const myRoutesRoutingStops = {
		id: 'my-route-routing-points-stops-layer',
		type: 'circle',
		source: 'my-routes-routing',
		paint: {
			'circle-radius': 8,
			'circle-color': '#383838',
			'circle-opacity': 1,
			'circle-stroke-width': 2,
			'circle-stroke-color': '#2a2a2a',
		},
	}

	const userRoutesStopsLayer = {
		id: 'user-routes-stops-layer',
		type: 'circle',
		source: 'my-routes-routing',
		paint: {
			'circle-radius': 8,
			'circle-color': '#383838',
			'circle-opacity': 1,
			'circle-stroke-width': 2,
			'circle-stroke-color': '#2a2a2a',
		},
	}

	const userRoutesStopsTextLayer = {
		id: 'user-routes-stops-text-layer',
		type: 'symbol',
		source: 'my-routes-routing',
		layout: {
			'text-field': ['get', 'enum'],
			'text-size': 14,
			'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
			'text-anchor': 'center',
			'text-offset': [0, 0],
			'text-allow-overlap': true,
		},
		paint: {
			'text-color': '#fff',
			'text-halo-width': 2,
		},
	}

	const myRoutesRoutingStopsText = {
		id: 'my-route-routing-points-stops-text-layer',
		type: 'symbol',
		source: 'my-routes-stops',
		layout: {
			'text-field': ['get', 'enum'],
			'text-size': 14,
			'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
			'text-anchor': 'center',
			'text-offset': [0, 0],
			'text-allow-overlap': true,
		},
		paint: {
			'text-color': '#fff',
			'text-halo-width': 2,
		},
	}

	const myRoutesRoutingStopsABText = {
		id: 'my-route-routing-points-stops-text-ab-layer',
		type: 'symbol',
		source: 'my-routes-routing',
		layout: {
			'text-field': ['get', 'enum'],
			'text-size': 14,
			'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
			'text-anchor': 'center',
			'text-offset': [0, 0],
			'text-allow-overlap': true,
		},
		paint: {
			'text-color': '#fff',
			'text-halo-width': 2,
		},
	}

	const mySelectedPlaceLayer = {
		id: 'my-selected-place-layer',
		type: 'circle',
		source: 'my-selected-place',
		paint: {
			'circle-radius': 8,
			'circle-color': ['get', 'color'],
			'circle-opacity': 1,
		},
	}

	const myRoutesRoutingPoints = {
		id: 'my-route-routing-points-layer',
		type: 'circle',
		source: 'my-routes-routing',
		paint: {
			'circle-radius': 8,
			'circle-color': '#383838',
			'circle-stroke-width': 2,
			'circle-stroke-color': '#2a2a2a',
		},
	}

	const imagesPinsLayer = {
		id: 'images-pins-layer',
		type: 'circle',
		source: 'images-pins',
		layout: {},
		paint: {
			'circle-radius': {
				base: 1.95,
				stops: [
					[12, 2],
					[22, 180],
				],
			},
			'circle-color': '#219F94',
			'circle-opacity': 1,
			'circle-stroke-width': 2,
			'circle-stroke-color': '#fff',
		},
		visibility: 'none',
	}

	const pinConnectionsLayer = {
		id: 'pin-connections-layer',
		type: 'line',
		source: 'pin-connections',
		layout: {
			'line-join': 'round',
			'line-cap': 'round',
		},
		paint: {
			'line-color': '#223b53',
			'line-width': 2,
		},
		visibility: 'none',
	}

	const imagesOriginPinsLayer = {
		id: 'images-pins-origin-layer',
		type: 'circle',
		source: 'images-pins-origin',
		layout: {},
		paint: {
			'circle-radius': {
				base: 2.95,
				stops: [
					[12, 2],
					[22, 180],
				],
			},
			'circle-color': '#e55e5e',
			'circle-opacity': 1,
			'circle-stroke-width': 2,
			'circle-stroke-color': '#fff',
		},
		visibility: 'none',
	}

	// sensitivity of layer tap/pan
	const eventRecognizerOptions = {
		pan: { threshold: 10 },
		tap: { threshold: 10 },
	}

	let networkData = !routeMenuOpened ? veloData : props.data
	if (adminOption === 'images') {
		networkData = { type: 'FeatureCollection', features: [] }
	}

	const handleDeleteWaypoint = index => {
		if (index === 0 && !myRouteStops.destination.features.length) {
			// clean up all stops
			journeyStateCleanup(true)
			return
		}

		if (index === 1 && !myRouteStops.viaStops.features.length) {
			// remove destination
			setMyRouteStops({
				...myRouteStops,
				destination: {
					type: 'FeatureCollection',
					features: [],
				},
			})

			journeyStateCleanup(false)
			return
		}

		if (index === 0) {
			// if there are waypoint, set start as first waypoint and remove first waypoint
			if (myRouteStops.viaStops.features.length) {
				setMyRouteStops({
					...myRouteStops,
					start: {
						type: 'FeatureCollection',
						features: [myRouteStops.viaStops.features[0]].map((feature, i) => {
							return {
								...feature,
								properties: {
									...feature.properties,
									enum: 'A',
									name: feature.properties.name,
								},
							}
						}),
					},
					viaStops: {
						type: 'FeatureCollection',
						features: myRouteStops.viaStops.features.slice(1).map((feature, i) => {
							return {
								...feature,
								properties: {
									...feature.properties,
									enum: i + 1,
									name: feature.properties.name || `Wegpunkt #${i + 1}`,
								},
							}
						}),
					},
				})

				return
			}

			// if there is destination set it as start and remove destination
			if (myRouteStops.destination.features.length) {
				setMyRouteStops({
					...myRouteStops,
					start: {
						type: 'FeatureCollection',
						features: [myRouteStops.destination.features[0]].map((feature, i) => {
							return {
								...feature,
								properties: {
									...feature.properties,
									enum: 'A',
									name: feature.properties.name,
								},
							}
						}),
					},
					destination: {
						type: 'FeatureCollection',
						features: [],
					},
				})

				journeyStateCleanup(false)
				return
			}
		}

		setMyRouteStops({
			...myRouteStops,
			viaStops: {
				type: 'FeatureCollection',
				features: myRouteStops.viaStops.features
					.filter((_, i) => i !== index - 1)
					.map((feature, i) => {
						return {
							...feature,
							properties: {
								...feature.properties,
								enum: i + 1,
								name: feature.properties.name || `Wegpunkt #${i + 1}`,
							},
						}
					}),
			},
		})
	}

	const sortStopFeatures = (startFeatureCollection, destinationFeatureCollection, stopFeatureCollection) => {
		// sort stops by projecting then to start / destination line and sorting by distance
		const startFeature = startFeatureCollection.features[0]
		const destinationFeature = destinationFeatureCollection.features[0]
		const stopFeatures = stopFeatureCollection.features

		const startCoord = startFeature.geometry.coordinates
		const destinationCoord = destinationFeature.geometry.coordinates
		const startDestinationLine = [startCoord, destinationCoord]

		if (stopFeatures.length === 0) {
			return stopFeatures
		}

		const stopsWithDistance = stopFeatures.map(stopFeature => {
			const projectedStop = nearestPointOnLine(
				{
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: startDestinationLine,
					},
				},
				stopFeature,
			)
			return { ...stopFeature, properties: { ...stopFeature.properties, projected: projectedStop.geometry.coordinates } }
		})
		// sort by distance from start
		stopsWithDistance.sort(
			(a, b) => distance(a.properties.projected, startCoord) - distance(b.properties.projected, startCoord),
		)

		stopFeatureCollection.features = stopsWithDistance.map((stop, i) => {
			return {
				...stop,
				properties: {
					...stop.properties,
					enum: i + 1,
					name: stop.properties.name || `Wegpunkt #${i + 1}`,
				},
			}
		})
	}

	const clearMySelectedPlace = () => {
		setMySelectedPlace({
			type: 'FeatureCollection',
			features: [],
		})
	}

	const generateLineStringFromFeatureCollection = featureCollection => {
		const newFeatureCollection = { type: 'FeatureCollection', features: [...featureCollection.features] }
		const coordinates = newFeatureCollection.features.reduce((acc, feature) => {
			return [...acc, ...feature.geometry.coordinates]
		}, [])

		return {
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: coordinates,
			},
		}
	}

	const handleSetAsStartPoint = feature => {
		setMyRouteStops({
			...myRouteStops,
			start: {
				type: 'FeatureCollection',
				features: [{ ...feature, properties: { ...feature.properties, enum: 'A' } }],
			},
		})
		clearMySelectedPlace()
	}

	const handleSetAsEndPoint = feature => {
		clearMySelectedPlace()
		// current endpoint should go to via stops and new endpoint should be set
		if (myRouteStops.destination.features.length) {
			setMyRouteStops({
				...myRouteStops,
				viaStops: {
					type: 'FeatureCollection',
					features: [
						...myRouteStops.viaStops.features,
						{
							...myRouteStops.destination.features[0],
							properties: {
								...myRouteStops.destination.features[0].properties,
								enum: myRouteStops.viaStops.features.length,
							},
						},
					],
				},
				destination: {
					type: 'FeatureCollection',
					features: [
						{ ...feature, properties: { ...feature.properties, enum: myRouteStops.viaStops.features.length } },
					],
				},
			})

			return
		}
		// in case there is no destination set
		setMyRouteStops({
			...myRouteStops,
			destination: {
				type: 'FeatureCollection',
				features: [{ ...feature, properties: { ...feature.properties, enum: 'B' } }],
			},
		})
	}

	const handleSetAsViaPoint = feature => {
		setMyRouteStops({
			...myRouteStops,
			viaStops: {
				type: 'FeatureCollection',
				features: [
					...myRouteStops.viaStops.features,
					{ ...feature, properties: { ...feature.properties, enum: myRouteStops.viaStops.features.length + 1 } },
				],
			},
		})
		clearMySelectedPlace()
	}

	const handleJourneyRouting = async (startFeatureCollection, destinationFeatureCollection) => {
		// if there are not start and destination then return
		if (!startFeatureCollection.features.length || !destinationFeatureCollection.features.length) {
			return
		}

		// in case start and destination are the same
		if (
			startFeatureCollection.features[0].geometry.coordinates[0] ===
				destinationFeatureCollection.features[0].geometry.coordinates[0] &&
			startFeatureCollection.features[0].geometry.coordinates[1] ===
				destinationFeatureCollection.features[0].geometry.coordinates[1]
		) {
			return
		}

		const start = startFeatureCollection.features[0]
		const destination = destinationFeatureCollection.features[0]
		setIsJourneyLoading(true)
		sortStopFeatures(startFeatureCollection, destinationFeatureCollection, myRouteStops.viaStops)
		const journey = await routeJourney({
			start: start.geometry.coordinates,
			destination: destination.geometry.coordinates,
			stops: myRouteStops.viaStops.features.map(feature => feature.geometry.coordinates),
		})
		setIsJourneyLoading(false)

		setJourneyFeatureCollection({
			type: 'FeatureCollection',
			features: journey.features.map(feature => {
				return {
					...feature,
					properties: {
						...feature.properties,
						color: '#3874ff',
					},
				}
			}),
		})

		// TODO: implement snapping to the journey line
		const journeyLine = generateLineStringFromFeatureCollection(journey)
		// update myRouteStops viaStops so that all via stops are on a new journey line
		myRouteStops.viaStops.features.forEach((stopFeature, index) => {
			const projectedStop = nearestPointOnLine(journeyLine, stopFeature)
			stopFeature.geometry.coordinates = projectedStop.geometry.coordinates
		})

		// repaint map with new via stops
		mapRef.current
			.getMap()
			.getSource('my-routes-stops')
			.setData({
				type: 'FeatureCollection',
				features: [...myRouteStops.viaStops.features],
			})

		// NOTE: here we do not want to snap
		// snap new coords for start and destination (closest to the journey line)
		// myRouteStops.start.features[0].geometry.coordinates = journey.features[0].geometry.coordinates[0]
		// myRouteStops.destination.features[0].geometry.coordinates =
		// 	journey.features[journey.features.length - 1].geometry.coordinates[0]

		// repaint map with new start and destination
		mapRef.current
			.getMap()
			.getSource('my-routes-routing')
			.setData({
				type: 'FeatureCollection',
				features: [...myRouteStops.start.features, ...myRouteStops.destination.features],
			})

		const lineString = {
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: generateLineStringFromFeatureCollection(journey).geometry.coordinates,
			},
		}
		setJourneyLineString(lineString)
		// instead we want to draw connections to main points
		setRouteConnections({
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [myRouteStops.start.features[0].geometry.coordinates, lineString.geometry.coordinates[0]],
					},
				},
				{
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [
							myRouteStops.destination.features[0].geometry.coordinates,
							lineString.geometry.coordinates[lineString.geometry.coordinates.length - 1],
						],
					},
				},
			],
		})

		// calculate new bounds and set viewport WebMercatorViewport
		if (!journeyFeatureCollection.features.length) {
			const bounds = [
				[lineString.geometry.coordinates[0][0], lineString.geometry.coordinates[0][1]],
				[
					lineString.geometry.coordinates[lineString.geometry.coordinates.length - 1][0],
					lineString.geometry.coordinates[lineString.geometry.coordinates.length - 1][1],
				],
			]

			const viewport = new WebMercatorViewport({
				width: window.innerWidth,
				height: window.innerHeight,
			}).fitBounds(bounds, {
				padding: { top: 150, bottom: 150, left: 500, right: 50 },
			})

			mapRef.current.getMap().fitBounds(bounds, {
				duration: 300,
				padding: { top: 150, bottom: 150, left: 500, right: 50 },
			})

			setTimeout(() => {
				setViewPort({
					latitude: viewport.latitude,
					longitude: viewport.longitude,
					zoom: viewport.zoom,
				})
			}, 360)
		}
	}

	const deboundedJourneyTrackerFreeMove = debounce(evt => {
		if (journeyTrackerDragging.current.dragging) {
			setJourneyTracker({
				fc: {
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [evt.lngLat[0], evt.lngLat[1]],
							},
						},
					],
				},
				visible: true,
			})
		}
	}, 4)

	const debouncedJourneyTrackerMove = debounce(evt => {
		if (!journeyTrackerDragging.current.dragging && myRoutesOpened && journeyFeatureCollection.features.length > 0) {
			const pointOnLine = nearestPointOnLine(journeyLineString, {
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: [evt.lngLat[0], evt.lngLat[1]],
				},
			})

			if (pointOnLine.properties.dist > 0.003) {
				setJourneyTracker({ fc: { type: 'FeatureCollection', features: [] }, visible: false })
				setShowInfoPopup(null)
				return
			}

			setJourneyTracker({
				fc: {
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: pointOnLine.geometry.coordinates,
							},
						},
					],
				},
				visible: true,
			})

			setShowInfoPopup({
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: pointOnLine.geometry.coordinates,
				},
			})

			return
		}
	}, 4)

	const getShowInfoPopupText = () => {
		const point = showInfoPopup.geometry.coordinates
		const closestFeature = journeyFeatureCollection.features.reduce(
			(acc, feature) => {
				const dist = distance(feature.geometry.coordinates[0], point)
				if (dist < acc.dist) {
					return { dist, feature }
				}
				return acc
			},
			{ dist: Infinity, feature: null },
		).feature

		if (!closestFeature) return ''
		return (
			<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
				<div>{closestFeature.properties.name}</div>
				<div>Road {closestFeature.properties.highway}</div>
			</div>
		)
	}

	const proximityToJourneyTracker = () => {
		if (!journeyTracker.fc.features[0]) {
			return Infinity
		}
		const distMeters = distance(
			{
				type: 'Point',
				coordinates: [currentCoordiantes.current.longitude, currentCoordiantes.current.latitude],
			},
			journeyTracker.fc.features[0].geometry,
			{ units: 'meters' },
		)

		if (distMeters > 10) {
			setJourneyTracker({ ...journeyTracker, visibility: false })
		}
		return distMeters
	}

	const selectExistingPoint = () => {
		const point = journeyTracker.fc.features[0].geometry.coordinates
		let existingPoint = null
		;[...myRouteStops.start.features, ...myRouteStops.destination.features, ...myRouteStops.viaStops.features].forEach(
			(feature, index) => {
				if (distance(feature.geometry.coordinates, point, { units: 'meters' }) < 20) {
					existingPoint = feature
					if (index < 2) {
						existingPoint =
							index === 0
								? { ...existingPoint, properties: { type: 'start' } }
								: { ...existingPoint, properties: { type: 'destination' } }
					}
				}
			},
		)

		return existingPoint
	}

	useEffect(() => {
		handleJourneyRouting(myRouteStops.start, myRouteStops.destination)
	}, [myRouteStops.start, myRouteStops.destination, myRouteStops.viaStops])

	const updateMyRouteStops = async (evt, start, destination, viaStops) => {
		const existingPoint = journeyTrackerDragging.current.existingPoint
		const place = await getPlace({ lon: evt.lngLat[0], lat: evt.lngLat[1] })
		if (existingPoint) {
			if (existingPoint.properties && existingPoint.properties.type === 'start') {
				start = {
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [evt.lngLat[0], evt.lngLat[1]],
							},
							properties: {
								enum: 'A',
								name: place.data.r_node_name || 'Wegpunkt A',
							},
						},
					],
				}
			} else if (existingPoint.properties && existingPoint.properties.type === 'destination') {
				destination = {
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [evt.lngLat[0], evt.lngLat[1]],
							},
							properties: {
								enum: 'B',
								name: place.data.r_node_name || 'Wegpunkt B',
							},
						},
					],
				}
			} else {
				// if existing point is part of via stops, remove that point from via stops
				// find index of existing point in via stops
				const index = viaStops.features.findIndex(feature => feature.properties.enum === existingPoint.properties.enum)
				const newViaStopFeatures = viaStops.features.filter((_, i) => i !== index)
				viaStops = {
					type: 'FeatureCollection',
					features: [
						...newViaStopFeatures,
						{
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [evt.lngLat[0], evt.lngLat[1]],
							},
							properties: {
								enum: existingPoint.properties.enum,
								name: place.data.r_node_name || `Wegpunkt #${existingPoint.properties.enum}`,
							},
						},
					],
				}
			}
		} else {
			// if no existing point is selected, add new point to via stops
			viaStops = {
				type: 'FeatureCollection',
				features: [
					...viaStops.features,
					{
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [evt.lngLat[0], evt.lngLat[1]],
						},
						properties: {
							enum: viaStops.features.length + 1,
							name: place.data.r_node_name || `Wegpunkt #${viaStops.features.length + 1}`,
						},
					},
				],
			}
		}

		sortStopFeatures(start, destination, viaStops)
		setMyRouteStops({
			...myRouteStops,
			start: start,
			destination: destination,
			viaStops,
		})
	}

	useEffect(() => {
		// on user places update we should update search results
		const newSearchResults = {
			type: 'FeatureCollection',
			features: [...userPlaces.features],
		}
		setSearchResults(newSearchResults)
	}, [userPlaces])

	useEffect(() => {
		if (search.text) {
			getPlaceFromAddressThrottoled(search)
		}
	}, [search])

	const getPlaceFromAddressThrottoled = React.useCallback(
		debounce(value => {
			const { text } = value
			getPlaceFromAddress({
				address: text,
			}).then(data => {
				// add user places to search results
				const upFeatures = userPlaces.features.filter(feature => {
					return feature.properties.name.toLowerCase().startsWith(text.toLowerCase())
				})
				setSearchResults({
					type: 'FeatureCollection',
					features: [...upFeatures, ...data.data.features],
				})
			})
		}, 500),
		[],
	)

	const setViewportAtPoint = coordinates => {
		const bounds = getBoundsFromCenter(coordinates)
		const viewport = new WebMercatorViewport({
			width: window.innerWidth,
			height: window.innerHeight,
		}).fitBounds(bounds, {
			padding: { top: 150, bottom: 150, left: 500, right: 50 },
		})

		setViewPort({
			...viewport,
		})
	}

	const handleOnSearchChange = value => {
		setSearch({
			text: value,
		})
		if (value.length === 0) {
			setSearchResults({
				type: 'FeatureCollection',
				features: userPlaces.features,
			})
		}
	}

	const handlePlaceClicked = feature => {
		const selectedFeature = {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: feature.geometry.coordinates,
			},
			properties: {
				...feature.properties,
				color: feature.properties.idle_id ? '#84b200' : '#383838',
			},
		}
		setMySelectedPlace({
			type: 'FeatureCollection',
			features: [selectedFeature],
		})
		setViewportAtPoint(selectedFeature.geometry.coordinates)
	}

	const handleOnSearchFocus = () => {
		// set placeSearchResults to user places
		if (userPlaces.features.length > 0 && !searchResults.features.length) {
			setSearchResults({
				type: 'FeatureCollection',
				features: userPlaces.features,
			})
		}
	}

	const handleOnSavePlace = async place => {
		const newPlace = (
			await saveUserPlace({
				userId: localStorageService.getUser().user_id,
				node: place.node,
			})
		).features[0]

		setSearch({
			text: '',
		})

		// we need to update user place list
		setUserPlaces({
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: newPlace.geometry.coordinates,
					},
					properties: {
						...newPlace.properties,
					},
				},
				...userPlaces.features,
			],
		})
	}

	const calcDistance = coordinates => {
		let dist = 0
		for (let i = 0; i < coordinates.length - 1; i++) {
			dist += distance(coordinates[i], coordinates[i + 1], { units: 'kilometers' })
		}

		// round to 2 decimal places
		return Math.round((dist + Number.EPSILON) * 100) / 100
	}

	const handleSaveRoute = async route => {
		if (journeyFeatureCollection.features.length === 0) {
			return
		}

		const lineStringFeature = {
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: generateLineStringFromFeatureCollection(journeyFeatureCollection).geometry.coordinates,
			},
			properties: {
				type: 'section',
				edges: journeyFeatureCollection.features.map(feature => feature.properties.ogc_fid),
			},
		}

		const startFeature = {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: myRouteStops.start.features[0].geometry.coordinates,
			},
			properties: {
				type: 'start',
				name: myRouteStops.start.features[0].properties?.name,
				idle_id: myRouteStops.start.features[0].properties?.idle_id,
				posmoUserNodeId: myRouteStops.start.features[0].properties?.id,
			},
		}

		const destinationFeature = {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: myRouteStops.destination.features[0].geometry.coordinates,
			},
			properties: {
				type: 'end',
				name: myRouteStops.destination.features[0].properties?.name,
				idle_id: myRouteStops.destination.features[0].properties?.idle_id,
				posmoUserNodeId: myRouteStops.destination.features[0].properties?.id,
			},
		}

		const routeColor = generateColors(1)[0]
		const newRoute = {
			name: route.name,
			type: route.type,
			color: routeColor,
			geojson: {
				type: 'FeatureCollection',
				features: [startFeature, lineStringFeature, destinationFeature],
			},
		}

		const savedRoute = await saveUserRoute(newRoute)
		savedRoute.color = '#2d2047'
		savedRoute.outlineColor = generateDarkerColor(savedRoute.color)
		savedRoute.selected = true
		setUserRoutes([...userRoutes, savedRoute])
		setSelectedTab('routeList')
		journeyStateCleanup(true)
	}

	const handleUserRouteClicked = (routeId, value) => {
		const newUserRoutes = userRoutes.map(route => {
			if (route.id === routeId) {
				route.selected = value
			}
			return route
		})

		setUserRoutes(newUserRoutes)
	}

	const handleDeleteUserRoute = async routeId => {
		await deleteUserRoute({ routeId })
		const newUserRoutes = userRoutes.filter(route => route.id !== routeId)
		setUserRoutes(newUserRoutes)
	}

	const renderUserRoutesStops = () => {
		const urStops = {
			type: 'FeatureCollection',
			features: [],
		}

		userRoutes
			.filter(route => {
				return route.selected
			})
			.forEach(route => {
				const start = route.geojson.features.find(feature => feature.properties.type === 'start')
				const destination = route.geojson.features.find(feature => feature.properties.type === 'end')
				urStops.features.push({ ...start, properties: { ...start.properties, enum: 'A' } })
				urStops.features.push({ ...destination, properties: { ...destination.properties, enum: 'B' } })
			})

		return urStops
	}

	const renderUserRoutesStopConnections = () => {
		const connections = { type: 'FeatureCollection', features: [] }
		userRoutes
			.filter(route => {
				return route.selected
			})
			.forEach(route => {
				const start = route.geojson.features.find(feature => feature.properties.type === 'start')
				const destination = route.geojson.features.find(feature => feature.properties.type === 'end')
				const lineString = route.geojson.features.find(feature => feature.geometry.type === 'LineString')

				const connectionAtoLineStart = {
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [start.geometry.coordinates, lineString.geometry.coordinates[0]],
					},
				}

				const connectionBtoLineEnd = {
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [
							destination.geometry.coordinates,
							lineString.geometry.coordinates[lineString.geometry.coordinates.length - 1],
						],
					},
				}

				connections.features.push(connectionAtoLineStart)
				connections.features.push(connectionBtoLineEnd)
			})

		return connections
	}

	const renderUserRoutes = () => {
		return {
			type: 'FeatureCollection',
			features: userRoutes
				.filter(route => {
					return route.selected
				})
				.map(route => {
					const start = route.geojson.features.find(feature => feature.properties.type === 'start')
					const destination = route.geojson.features.find(feature => feature.properties.type === 'end')
					const lineString = route.geojson.features.find(feature => feature.geometry.type === 'LineString')
					route.distance = calcDistance(lineString.geometry.coordinates)
					return {
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: lineString.geometry.coordinates,
						},
						properties: {
							name: route.name,
							type: route.type,
							start: start.properties,
							destination: destination.properties,
							color: route.color,
							outlineColor: route.outlineColor,
							id: route.id,
						},
					}
				}),
		}
	}

	return (
		<div>
			{query.showMyRoutes ? (
				<MyRoutes
					startInputFocused={startInputFocused}
					searchRef={searchRef}
					selectedTab={selectedTab}
					search={search}
					searchResults={searchResults}
					myRouteStops={myRouteStops}
					userRoutes={userRoutes}
					journeyFeatureCollection={journeyFeatureCollection}
					setSearch={setSearch}
					onPlaceEditorClose={() => {
						clearMySelectedPlace()
					}}
					onDeleteRoute={routeId => {
						handleDeleteUserRoute(routeId)
					}}
					onDeleteWaypoint={index => {
						handleDeleteWaypoint(index)
					}}
					onUserRouteClicked={(routeId, value) => {
						handleUserRouteClicked(routeId, value)
					}}
					onSetSelectedTab={tab => {
						// journeyStateCleanup(true)
						clearMySelectedPlace()
						setSelectedTab(tab)
					}}
					onSetAsStartPoint={feature => {
						handleSetAsStartPoint(feature)
					}}
					onSetAsEndPoint={feature => {
						handleSetAsEndPoint(feature)
					}}
					onSetAsViaPoint={feature => {
						handleSetAsViaPoint(feature)
					}}
					onSearchFocus={value => {
						handleOnSearchFocus(value)
					}}
					onPlaceClicked={feature => handlePlaceClicked(feature)}
					onSearchChange={value => {
						handleOnSearchChange(value)
					}}
					onSaveRoute={route => {
						handleSaveRoute(route)
					}}
					onSearchClose={() => {
						setSearch({
							text: '',
							idle_id: null,
						})
						setSearchResults({
							type: 'FeatureCollection',
							features: userPlaces.features,
						})
						clearMySelectedPlace()
					}}
					onSavePlace={place => {
						handleOnSavePlace(place)
					}}
					myRoutesOpened={myRoutesOpened}
					routeTypeChooser={routeTypeChooser}
					onSetRouteTypeChooser={choice => {
						setRouteTypeChooser(choice)
						if (choice === 'officialRoutes') {
							journeyStateCleanup(true)
							setMyRouteOpened(false)
						} else {
							setMyRouteOpened(true)
						}
					}}
					removeStart={() => removeStart()}
				/>
			) : (
				''
			)}
			<RouteEditorMenu
				city={props.city}
				setSelectedOfficalRoute={feature => setSelectedOfficalRoute(feature)}
				onEdgesStatusChange={edges => handleMappingEdgesStatus(edges)}
				adminOption={adminOption}
				setAdminOption={option => {
					setAdminOption(option)
				}}
				setRouteId={routeId => setRouteId(routeId)}
				setData={data => props.setData(data)}
				veloCityData={veloCityData}
				setVeloCityData={data => setVeloCityData(data)}
				setRouteMenuOpened={routeMenuOpened => setRouteMenuOpened(routeMenuOpened)}
				onDelete={(route_id, ogc_fid) => props.onRouteEdgeDelete(route_id, ogc_fid)}
				data={props.data}
				onCommit={mapping => {
					handleOnCommit(mapping)
				}}
				selectedFeatures={selectedFeatures.features}
				onFreezePointSet={point => handleFreezePointSet(point)}
				setViewPort={setViewPort}
				clickedOgcFid={clickedOgcFid}
				setClickedOgcFid={setClickedOgcFid}
				setHighlightedOgcFid={setHighlightedOgcFid}
			/>
			<MapContext.Provider>
				{!routeMenuOpened ? (
					<MainMenu
						onClassify={classification => handleClassify(classification)}
						setGeoLocateStyle={setGeoLocateStyle}
						onClassificationChange={classification => {
							handleClassificationChange(classification)
						}}
						active={activeMenu}
						data={selectedFeatures}
					/>
				) : (
					''
				)}
				<InternalMap viewport={viewport}>
					<MapGL
						ref={mapRef}
						mapboxApiAccessToken={TOKEN}
						// style = {{ position: 'absolute', width: '100%', top: 0, bottom: 0 }}
						width='100vw'
						height='100vh'
						mapStyle={mapStyle}
						//mapbox://styles/mapbox/satellite-v9
						eventRecognizerOptions={eventRecognizerOptions}
						clickRadius={15}
						touchZoom={true}
						scrollZoom={true}
						interactiveLayerIds={
							routeTypeChooser === 'myRoutes'
								? [
										'journey-layer',
										'journey-tracker-layer',
										'images-pins-layer',
										'images-pins-origin-layer',
										'journey-layer',
										'journey-tracker-layer',
								  ]
								: ['network-layer', 'images-pins-layer', 'images-pins-origin-layer']
						}
						dragPan={journeyTrackerDragging.current.dragging ? false : true}
						onMouseMove={evt => {
							currentCoordiantes.current = { latitude: evt.lngLat[1], longitude: evt.lngLat[0] }
							debouncedJourneyTrackerMove(evt)
							deboundedJourneyTrackerFreeMove(evt)
						}}
						onHover={evt => {
							if (routeMenuOpened && evt.features[0]) {
								setPopup({
									lat: evt.lngLat[1],
									lon: evt.lngLat[0],
									ogc_fid: evt.features[0].properties.ogc_fid,
									image_name: ctx.generateImageURL({ imageName: evt.features[0].properties.image_name }, 120),
								})
							}
						}}
						onMouseDown={evt => {
							setShowInfoPopup(null) // hide info popup
							journeyTrackerDragging.current.dragging = proximityToJourneyTracker() < 10
							if (journeyTrackerDragging.current.dragging) {
								journeyTrackerDragging.current.existingPoint = selectExistingPoint()
							}
						}}
						onMouseUp={evt => {
							if (journeyTrackerDragging.current.dragging) {
								journeyTrackerDragging.current.dragging = false

								let start = myRouteStops.start
								let destination = myRouteStops.destination
								let viaStops = myRouteStops.viaStops

								updateMyRouteStops(evt, start, destination, viaStops)
							}
						}}
						onClick={async evt => {
							if (
								myRoutesOpened &&
								myRouteStops.start.features.length === 1 &&
								myRouteStops.destination.features.length === 1
							) {
								return
							}

							const place = await getPlace({ lon: evt.lngLat[0], lat: evt.lngLat[1] })

							if (myRoutesOpened && selectedTab === 'createRoute') {
								if (myRouteStops.start.features.length === 0) {
									setMyRouteStops({
										start: {
											type: 'FeatureCollection',
											features: [
												{
													type: 'Feature',
													geometry: {
														type: 'Point',
														coordinates: [evt.lngLat[0], evt.lngLat[1]],
													},
													properties: { enum: 'A', name: place.data.r_node_name || 'Wegpunkt A' },
												},
											],
										},
										destination: myRouteStops.destination,
										viaStops: myRouteStops.viaStops,
									})

									return
								}

								if (myRouteStops.destination.features.length === 0) {
									setMyRouteStops({
										start: myRouteStops.start,
										destination: {
											type: 'FeatureCollection',
											features: [
												{
													type: 'Feature',
													geometry: {
														type: 'Point',
														coordinates: [evt.lngLat[0], evt.lngLat[1]],
													},
													properties: {
														enum: 'B',
														name: place.data.r_node_name || 'Wegpunkt B',
													},
												},
											],
										},
										viaStops: myRouteStops.viaStops,
									})
								}
							}
							if (!routeMenuOpened) return

							// bring velo = 1 edges to front.
							// evt.features.sort((f0 , f1) => { return f1.properties.velo - f0.properties.velo  });

							// Convert 'null' property values to null
							// https://github.com/mapbox/mapbox-gl-js/issues/8497
							evt.features = evt.features?.map(feature => {
								for (const [propertyKey, propertyValue] of Object.entries(feature.properties)) {
									feature.properties[propertyKey] = propertyValue === 'null' ? null : propertyValue
								}
								return feature
							})
							// Do not allow clicking expired edges so filter them out
							const clickedFeature = evt.features?.filter(feature => !feature.properties.expired_at)?.at(0)

							if (clickedFeature) {
								const clickedOgcFid = clickedFeature.properties.ogc_fid
								if (selectedRouteData.features.find(feature => feature.properties.ogc_fid === clickedOgcFid)) {
									// Clicked edge already belongs to the route, highlight it
									setClickedOgcFid(clickedOgcFid)
								} else if (
									!selectedFeatures.features.find(feature => feature.properties.ogc_fid === clickedOgcFid)
								) {
									// Get feature from original data, event geometry does not always cover the whole edge for some reason
									const feature = props.data.features.find(
										feature => feature.properties.ogc_fid === clickedOgcFid,
									)
									setSelectedFeatures({
										type: 'FeatureCollection',
										properties: {},
										features: [
											...selectedFeatures.features,
											{
												...feature,
												properties: { ...feature.properties, color: '#5FABE3', lngLat: evt.lngLat },
											},
										],
									})
								} else {
									// remove feature from the list, unclick
									const filteredFeatures = selectedFeatures.features.filter(feature => {
										feature.properties.color = '#5FABE3'
										return feature.properties.ogc_fid !== clickedOgcFid
									})

									setSelectedFeatures({
										type: 'FeatureCollection',
										properties: {},
										features: [...filteredFeatures],
									})
								}
							}
						}}>
						{showInfoPopup && showInfoPopup.geometry.coordinates.length > 0 ? (
							<Popup
								latitude={showInfoPopup.geometry.coordinates[1]}
								longitude={showInfoPopup.geometry.coordinates[0]}
								closeButton={false}
								anchor='bottom'
								className='journey-tracker-popup-info'
								tipSize={6}
								offsetTop={-15}>
								<div>
									<div style={{ fontSize: 12, color: '#444', fontFamily: 'sans-serif' }}>
										Ziehen, um die Route zu ändern
									</div>
									<div style={{ marginTop: 8, fontSize: 10, color: '#999', fontFamily: 'sans-serif' }}>
										{getShowInfoPopupText()}
									</div>
								</div>
							</Popup>
						) : (
							''
						)}
						{routeMenuOpened && popup ? (
							<Popup latitude={popup.lat} longitude={popup.lon} closeButton={false} anchor='bottom' offsetTop={-25}>
								<div style={{ fontSize: 10, color: '#888' }}>ogc_fid {popup.ogc_fid}</div>
								<div style={{ marginTop: 12 }}>{popup.image_name ? <img src={popup.image_name} /> : ''}</div>
							</Popup>
						) : (
							''
						)}
						{/* <NavigationControl style={navControlStyle} /> */}
						<GeolocateControl
							style={routeMenuOpened ? { right: 6, top: 20, position: 'fixed' } : geoLocateControlStyle}
							positionOptions={{ enableHighAccuracy: true }}
							trackUserLocation={true}
						/>
						<Source id='official_route' type='geojson' data={selectedOfficalRoute}>
							{routeMenuOpened ? <Layer {...officialRouteLayer} /> : ''}
						</Source>
						{/* <Source id="veloplan" type="geojson" data={veloCityData}>
							{routeMenuOpened ? <Layer {...veloplanLayer} /> : ''}
						</Source> */}
						<Source id='userRoutes' type='geojson' data={renderUserRoutes()}>
							{userRoutes.length && selectedTab !== 'createRoute' ? <Layer {...userRoutesOutlineLayer} /> : ''}
							{userRoutes.length && selectedTab !== 'createRoute' ? <Layer {...userRoutesLayer} /> : ''}
						</Source>
						<Source id='my-user-routes-routing-connections' type='geojson' data={renderUserRoutesStopConnections()}>
							{userRoutes.length && selectedTab !== 'createRoute' ? (
								<Layer {...myRoutesUserRoutingConnections} />
							) : (
								''
							)}
						</Source>
						<Source id='userRoutesStops' type='geojson' data={renderUserRoutesStops()}>
							{userRoutes.length && selectedTab !== 'createRoute' ? <Layer {...userRoutesStopsLayer} /> : ''}
							{userRoutes.length && selectedTab !== 'createRoute' ? <Layer {...userRoutesStopsTextLayer} /> : ''}
						</Source>
						<Source id='selectedRoute' type='geojson' data={selectedRouteData}>
							{routeMenuOpened ? <Layer {...selectedRouteLayer} /> : ''}
						</Source>
						<Source id='network' type='geojson' data={networkData}>
							{routeTypeChooser === 'myRoutes' ? '' : <Layer {...networkLayer} beforeId='road-label-simple' />}
						</Source>
						<Source id='classified-features-layer' type='geojson' data={classifiedFeatures}>
							{routeMenuOpened || routeTypeChooser === 'myRoutes' ? (
								''
							) : (
								<Layer {...classifiedFeaturesLayer} beforeId='road-label-simple' />
							)}
						</Source>
						<Source id='feature-selected' type='geojson' data={selectedFeatures}>
							<Layer {...featureSelectedLayerBorder} />
							<Layer {...featureSelectedLayer} />
						</Source>
						<Source
							id='images-pins'
							type='geojson'
							data={
								routeMenuOpened && adminOption === 'images'
									? imagesPinsFeatures
									: { type: 'FeatureCollection', features: [] }
							}>
							<Layer {...imagesPinsLayer} />
						</Source>
						<Source
							id='images-pins-origin'
							type='geojson'
							data={
								routeMenuOpened && adminOption === 'images'
									? imagesPinsOriginFeatures
									: { type: 'FeatureCollection', features: [] }
							}>
							<Layer {...imagesOriginPinsLayer} />
						</Source>
						<Source
							id='pin-connections'
							type='geojson'
							data={
								routeMenuOpened && adminOption === 'images'
									? pinConnectionsFeatures
									: { type: 'FeatureCollection', features: [] }
							}>
							<Layer {...pinConnectionsLayer} />
						</Source>
						<Source
							id='freeze-point'
							type='geojson'
							data={
								routeMenuOpened && !(adminOption === 'edge_status')
									? freezePointFeature
									: { type: 'FeatureCollection', features: [] }
							}>
							<Layer {...freezePointLayer} />
						</Source>
						<Source id='journey' type='geojson' data={journeyFeatureCollection}>
							<Layer {...journeyLayerOutline} />
							<Layer {...journeyLayer} />
							{isJourneyLoading ? <Layer {...journeyLoadingLayer} /> : ''}
						</Source>
						<Source id='journey-tracker' type='geojson' data={journeyTracker.fc}>
							<Layer {...journeyTrackerLayer} />
						</Source>
						<Source id='my-routes-stops' type='geojson' data={myRouteStops.viaStops}>
							<Layer {...myRoutesRoutingStops} />
							<Layer {...myRoutesRoutingStopsText} />
						</Source>
						<Source id='my-routes-routing-connections' type='geojson' data={routeConnections}>
							<Layer {...myRoutesRoutingConnections} />
						</Source>
						<Source
							id='my-routes-routing'
							type='geojson'
							data={{
								type: 'FeatureCollection',
								features: [
									...myRouteStops.start.features.map(feature => {
										return {
											...feature,
											properties: {
												...feature.properties,
											},
										}
									}),
									...myRouteStops.destination.features.map(feature => {
										return {
											...feature,
											properties: {
												...feature.properties,
											},
										}
									}),
								],
							}}>
							<Layer {...myRoutesRoutingPoints} />
							<Layer {...myRoutesRoutingStopsABText} />
						</Source>
						<Source id='my-selected-place' type='geojson' data={mySelectedPlace}>
							<Layer {...mySelectedPlaceLayer} />
						</Source>
						<Source id='feature-highlighted' type='geojson' data={highlightedFeatures}>
							{routeMenuOpened && <Layer {...featureHighlightedLayer} />}
						</Source>
					</MapGL>
				</InternalMap>
				{routeMenuOpened ? <SwitchLayer mapRef={mapRef} setMapStyle={setMapStyle} mapStyle={mapStyle} /> : ''}
			</MapContext.Provider>
			<DesktopMenu />
			{/* <div style={{ width: '100%', zIndex: 5, position: 'absolute', bottom: 0}}>
				<LinearProgressWithLabel value={(classifiedFeatures.features.length / props.data.features.filter(d => { return d.properties.velo === 1 }).length) * 100} />
			</div> */}
		</div>
	)
}

export default withRouter(Map)
