import React from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Badge from '@material-ui/core/Badge'
import BuildIcon from '@material-ui/icons/Build'
import deleteRouteEdge from '../api/deleteRouteEdge'
import CircularIndeterminate from './CircularIndeterminate'
import localStorageService from '../services/localStorageService'
import getVeloplanEdges from '../api/getVeloplanEdges'
import getEdges from '../api/getEdges'
import { updateRouteProps } from '../api'
import { Input } from '@material-ui/core'
import { getCityHeader } from '../api/request'

const useStyles = makeStyles(theme => ({
	root: {
		textAlign: 'left',
		position: 'absolute',
		top: 90,
		zIndex: 4,
		backgroundColor: '#fff',
		borderRadius: 20,
		border: 'none',
		width: 450,
		left: 16,
		visibility: 'hidden',
	},
	routeGroup: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
	},
	totalEdges: {
		margin: theme.spacing(1),
		fontSize: 11,
		color: '#888',
		position: 'relative',
	},
	existingMappings: {
		display: 'flex',
		gap: 16,
		fontSize: 12,
		color: '#444',
		height: 25,
		transition: 'background 0.5s ease-out',
	},
}))

export default function RouteEditorMenu(props) {
	const classes = useStyles()

	const [routeId, setRouteId] = React.useState('')
	const [statusId, setStatusId] = React.useState(4)
	const [typeId, setTypeId] = React.useState(3)
	const [routeMenuOpened, setRouteMenuOpened] = React.useState(false)
	const [mapping, setMapping] = React.useState([])
	const [mappingEdgesStatus, setMappingEdgesStatus] = React.useState([])
	const [edgeStatusValue, setEdgeStatusValue] = React.useState(1)
	const [loader, setLoader] = React.useState(false)
	const [routes, setRoutes] = React.useState([])
	const [routeProps, setRouteProps] = React.useState(() => {
		const properties = {}
		props.data.features.forEach(feature => {
			const routeId = feature.properties.route_id
			properties[routeId] = {
				...feature.properties,
				route_start_point_freeze: properties[routeId]?.route_start_point_freeze || !!feature.properties.route_start_point,
				expired: properties[routeId]?.expired || !!feature.properties.expired_at,
			}
		})

		return properties
	})
	const edgeContainerRef = React.useRef()

	React.useEffect(() => {
		if (!props.veloCityData.features.length && localStorageService.isAdmin()) {
			const city = getCityHeader()
			getVeloplanEdges(city)
				.then(data => data.data)
				.then(data => {
					data.features
						.sort((a, b) => {
							return parseInt(a.properties.nummer) - parseInt(b.properties.nummer)
						})
						.forEach(d => {
							d.properties.line_width = 12
							d.properties.color = 'green'
						})

					if (city === 'basel') {
						props.setSelectedOfficalRoute({ type: 'FeatureCollection', features: data.features })
					} else if (city === 'muenchen') {
						props.setSelectedOfficalRoute({ type: 'FeatureCollection', features: data.features })
					} else {
						props.setSelectedOfficalRoute(data.features[0])
					}
					props.setVeloCityData(data)
				})
				.catch(err => {
					console.log(err)
				})
		}
	}, [props.veloCityData])

	React.useEffect(() => {
		if (localStorageService.isAdmin()) {
			getEdges()
				.then(data => data.data)
				.then(data => {
					data.features.forEach(d => {
						d.properties.line_width = d.properties.route_id ? 4 : 2
						d.properties.color = d.properties.route_id ? '#B3ACBD' : '#e1e1e1'

						// override settings for routes in planning
						d.properties.color = d.properties.status === 2 ? '#7b787f' : d.properties.color
						d.properties.line_width = d.properties.status === 2 ? 8 : d.properties.line_width
					})
					data.features.sort((a, b) => {
						return a.properties.status - b.properties.status
					})

					setMapping([])
					props.setData(data)
				})
		}
	}, [props.city])

	const getStatus = statusId => {
		switch (statusId) {
			case 1:
				return 'Konzept'
			case 2:
				return 'Planung'
			case 3:
				return 'Im Bau'
			case 4:
				return 'Fertig'
			default:
				return 'Fertig'
		}
	}

	const handleRouteChange = newRouteId => {
		setRouteId(newRouteId)
		props.setRouteId(newRouteId)
		setMapping(
			props.data.features
				.filter(feature => {
					return feature.properties.route_id === newRouteId
				})
				.map(feature => feature.properties),
		)
	}

	const handleUpdateRouteProps = async (route_id, properties) => {
		await updateRouteProps({ route_id, ...properties })
		const newData = { type: 'FeatureCollection', features: [] }
		newData.features = props.data.features.map(feature => {
			if (feature.properties.route_id === route_id) {
				feature.properties = { ...feature.properties, ...properties }
			}

			return feature
		})

		props.setData(newData)
	}

	const handleDeleteRouteEdge = (route_id, ogc_fid, newValue) => {
		if (!newValue) {
			deleteRouteEdge(route_id, ogc_fid)
				.then(data => data.data)
				.then(() => {
					props.onDelete(route_id, ogc_fid)
					setMapping(
						mapping.filter(m => {
							return m.ogc_fid !== ogc_fid
						}),
					)
				})
				.catch(err => {
					// can't remove.
				})
		} else {
			setMapping(
				mapping.filter(m => {
					return m.ogc_fid !== ogc_fid
				}),
			)
		}
	}

	React.useEffect(() => {
		const featuresWithCurrentRouteId = props.data.features.filter(feature => {
			return feature.properties.route_id === routeId
		})
		const featuresToAddToState = []
		featuresWithCurrentRouteId.forEach(feature => {
			if (
				!mapping.find(m => {
					return feature.properties.ogc_fid === m.ogc_fid
				})
			) {
				featuresToAddToState.push(feature)
			}
		})

		setMapping([
			...mapping,
			...featuresToAddToState.map(feature => {
				return feature.properties
			}),
		])
		setLoader(false)
	}, [props.data, props.city])

	React.useEffect(() => {
		const city = getCityHeader()
		axios
			.get(`${process.env.REACT_APP_API}/v1/getNetworkRoutes`, {
				headers: {
					city,
				},
			})
			.then(data => data.data)
			.then(data => {
				setRoutes(data)
				handleRouteChange(data[0]?.id ?? '')
			})
			.catch(err => {
				setRoutes([])
			})
	}, [props.city])

	React.useEffect(() => {
		if (routeMenuOpened && props.selectedFeatures) {
			if (props.selectedFeatures.length && !(routeProps[routeId] && routeProps[routeId].route_start_point_freeze)) {
				const [lon, lat] = props.selectedFeatures[props.selectedFeatures.length - 1].properties.lngLat

				props.onFreezePointSet({
					type: 'Point',
					coordinates: [parseFloat(lon.toFixed(7)), parseFloat(lat.toFixed(7))],
				})

				setRouteProps({
					...routeProps,
					[routeId]: {
						...routeProps[routeId],
						route_start_point: {
							type: 'Point',
							coordinates: [parseFloat(lon.toFixed(7)), parseFloat(lat.toFixed(7))],
						},
					},
				})
			}

			setMappingEdgesStatus([
				...props.selectedFeatures.map(feature => {
					return {
						...feature.properties,
						status: statusId,
						route_id: routeId,
						type: typeId,
						created_at: new Date().getTime(),
						newValue: true,
					}
				}),
			])

			let newMapping = mapping.filter(m => {
				return (
					!m.newValue ||
					(m.newValue === true &&
						props.selectedFeatures.find(feature => {
							return feature.properties.ogc_fid === m.ogc_fid
						}))
				)
			})

			let newFeaturesProperties = props.selectedFeatures.map(feature => {
				return {
					...feature.properties,
					status: statusId,
					route_id: routeId,
					type: typeId,
					created_at: new Date().getTime(),
					newValue: true,
				}
			})

			newFeaturesProperties = newFeaturesProperties.filter(feature => {
				return !newMapping.find(m => {
					return m.ogc_fid === feature.ogc_fid
				})
			})

			setMapping([...newMapping, ...newFeaturesProperties])
		}
	}, [props.selectedFeatures])

	React.useEffect(() => {
		let timeoutId
		// Find edge element from edge list
		const edgeElement = edgeContainerRef.current.querySelector(`div[data-id='${props.clickedOgcFid}']`)
		if (edgeElement) {
			edgeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
			props.setHighlightedOgcFid(props.clickedOgcFid)

			timeoutId = setTimeout(() => props.setClickedOgcFid(null), 2000)
		}
		return () => { timeoutId && clearTimeout(timeoutId) }
	}, [props.clickedOgcFid]);

	const listSelectedEdges = () => {
		return mapping
			.sort((a, b) => {
				return new Date(b.created_at) - new Date(a.created_at)
			})
			.map(edge => {
				return (
					<div
						key={edge.ogc_fid}
						data-id={edge.ogc_fid}
						className={classes.existingMappings}
						style={{
							color: !edge.newValue ? '#222' : '#777',
							background: edge.ogc_fid === props.clickedOgcFid ? '#ffaa00' : 'none',
						}}
						onClick={() => {
							props.setHighlightedOgcFid(edge.ogc_fid)
							// Move viewport to the highlighted edge
							const coordinates = props.data.features.find(feature =>
								feature.properties.ogc_fid === edge.ogc_fid
							).geometry.coordinates[0]
							props.setViewPort({ longitude: coordinates[0], latitude: coordinates[1], zoom: 17 })
						}}>
						<div style={{ width: 4, backgroundColor: edge.route_id ? '#2D2047' : 'green' }}></div>
						{edge.expired_at && <span title="Expired">⚠️</span>}
						<div style={{ minWidth: 40 }}>{edge.ogc_fid}</div>
						<div
							style={{
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								flex: 1,
							}}>
							{edge.name}
						</div>
						<div>
							<span style={{ color: !edge.newValue ? '#222' : '#777' }}>{getStatus(edge.status)}</span>
						</div>
						<div>
							<IconButton
								style={{ padding: 0, margin: '0 16px 0 0' }}
								onClick={(e) => {
									// Do not propagate click to parent div (highlighting)
									e.stopPropagation()
									handleDeleteRouteEdge(routeId, edge.ogc_fid, edge.newValue)
								}}>
								<DeleteIcon style={{ fontSize: 16 }} />
							</IconButton>
						</div>
					</div>
				)
			})
	}

	const listAllSelectedEdges = () => {
		return mappingEdgesStatus
			.sort((a, b) => {
				return new Date(b.created_at) - new Date(a.created_at)
			})
			.map(edge => {
				return (
					<div
						key={edge.ogc_fid}
						className={classes.existingMappings}
						style={{ color: !edge.newValue ? '#222' : '#777' }}>
						<div style={{ width: 2, backgroundColor: edge.status === 2 ? '#2D2047' : 'gray' }}></div>
						{edge.expired_at && <span title="Expired">⚠️</span>}
						<div style={{ marginLeft: 4 }}>{edge.ogc_fid}</div>
						<div style={{ marginLeft: 4 }}>{edge.name} →</div>
						<div style={{ marginLeft: 4 }}>
							<span style={{ color: !edge.newValue ? '#222' : '#777' }}>{getStatus(edge.status)}</span>
						</div>
					</div>
				)
			})
	}

	const listOfficialRoutes = () => {
		const city = getCityHeader()
		if (city === 'basel') {
			return null
		}

		const officalRoutesDom =
			props.veloCityData &&
			props.veloCityData.features
				.sort((a, b) => {
					return parseInt(a.properties.nummer) - parseInt(b.properties.nummer)
				})
				.map(feature => {
					return (
						<option
							key={`or_${feature.properties.nummer || feature.properties.name}`}
							value={feature.properties.nummer || feature.properties.name}>
							(
							{feature.properties.bezeichnung
								? feature.properties.nummer + ' ' + feature.properties.bezeichnung
								: feature.properties.name}
						</option>
					)
				})

		return (
			<div style={{ position: 'absolute', top: 28, right: 85, zIndex: 4, fontSize: 14 }}>
				<div id='oroutes'>
					<select
						onChange={e => {
							const feature = props.veloCityData.features.find(feature => {
								return (
									parseInt(feature.properties.nummer) === parseInt(e.target.value) ||
									feature.properties.name === e.target.value
								)
							})

							props.setSelectedOfficalRoute(feature)
						}}
						style={{ fontSize: 14, padding: '8px 4px', outline: 'none', border: '1px solid #ddd', borderRadius: 8 }}>
						{officalRoutesDom}
					</select>
				</div>
			</div>
		)
	}

	const renderRouteProps = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', gap: 16, alignItems: 'center' }}>
				<div style={{ display: 'flex', gap: 16, marginLeft: 16 }}>
					<FormControlLabel
						style={{ color: '#444', fontSize: 14, display: 'flex' }}
						control={
							<Checkbox
								color='primary'
								checked={routeProps[routeId] && routeProps[routeId].route_visible ? true : false}
								onChange={e => {
									handleUpdateRouteProps(routeId, {
										route_visible: e.target.checked ? 1 : 0,
									})
									setRouteProps({
										...routeProps,
										[routeId]: { ...routeProps[routeId], route_visible: e.target.checked ? 1 : 0 },
									})
								}}
								name='route_visible'
							/>
						}
						label='Visible'
					/>
					<FormControlLabel
						style={{ color: '#444', margin: 0, fontSize: 14, display: 'flex' }}
						control={
							<Checkbox
								color='primary'
								checked={routeProps[routeId] && routeProps[routeId].route_default ? true : false}
								onChange={e => {
									handleUpdateRouteProps(routeId, {
										route_default: e.target.checked ? 1 : 0,
									})
									setRouteProps({
										...routeProps,
										[routeId]: { ...routeProps[routeId], route_default: e.target.checked ? 1 : 0 },
									})
								}}
								name='route_default'
							/>
						}
						label='Default'
					/>
				</div>
				<div style={{ display: 'flex', fontSize: 12, marginRight: 16, width: 140 }}>
					<Input
						type='text'
						placeholder='Route Number'
						onChange={e => {
							handleUpdateRouteProps(routeId, {
								route_number: !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null,
							})
							setRouteProps({
								...routeProps,
								[routeId]: { ...routeProps[routeId], route_number: e.target.value },
							})
						}}
						value={(routeProps[routeId] && routeProps[routeId].route_number) || ''}
					/>
				</div>
			</div>
		)
	}

	const renderLonLat = () => {
		const startPoint = routeProps[routeId] && routeProps[routeId].route_start_point

		return (
			<div style={{ fontSize: 12, display: 'flex', justifyContent: 'space-between', gap: 16, alignItems: 'center' }}>
				<FormControlLabel
					style={{ color: '#444', margin: 0, fontSize: 14, display: 'block' }}
					control={
						<Checkbox
							color='primary'
							checked={routeProps[routeId] && routeProps[routeId].route_start_point_freeze ? true : false}
							onChange={e => {
								if (e.target.checked) {
									// in case freeze is set, we will update start_point for this routeId
									handleUpdateRouteProps(routeId, {
										route_start_point: (routeProps[routeId] && routeProps[routeId].route_start_point) || null,
									})
								}

								setRouteProps({
									...routeProps,
									[routeId]: {
										...routeProps[routeId],
										route_start_point_freeze: e.target.checked,
									},
								})
							}}
							name='route_freeze'
						/>
					}
					label='Freeze'
				/>
				<Input
					disabled={routeProps[routeId] && routeProps[routeId].route_start_point_freeze}
					style={{ width: 140, fontSize: 12, padding: '4px 8px', marginRight: 16 }}
					type='text'
					value={startPoint && startPoint.coordinates.join(',')}
				/>
			</div>
		)
	}

	return (
		<div>
			{routeMenuOpened ? (
				<div
					style={{
						position: 'absolute',
						zIndex: 107,
						bottom: 100,
						right: 65,
						width: 148,
						display: 'flex',
						height: 51,
						borderRadius: 20,
						backgroundColor: '#fff',
					}}>
					<select
						style={{
							position: 'relative',
							border: 'none',
							right: -17,
							outline: 'none',
						}}
						onChange={e => {
							props.setAdminOption(e.target.value)
						}}>
						<option value='route_builder'>Route builder</option>
						<option value='edge_status'>Edges status</option>
						<option value='images'>Images</option>
					</select>
				</div>
			) : (
				''
			)}
			{localStorageService.isAdmin() ? (
				<div style={{ zIndex: 5, position: 'absolute', right: 6, bottom: 100 }}>
					<IconButton
						onClick={e => {
							props.setRouteMenuOpened(!routeMenuOpened)
							setRouteMenuOpened(!routeMenuOpened)
						}}
						style={{ backgroundColor: '#fff', borderRadius: '50%', boxShadow: '0 1px 6px 2px #e0e0e0' }}
						size='medium'>
						<BuildIcon style={{ fontSize: 28 }} />
					</IconButton>
				</div>
			) : (
				''
			)}
			{routeMenuOpened && props.veloCityData.features.length ? listOfficialRoutes() : ''}
			{!props.veloCityData.features.length && routeMenuOpened ? (
				<CircularIndeterminate style={{ zIndex: 10011 }} />
			) : props.adminOption !== 'edge_status' ? (
				<div
					className={classes.root}
					style={{
						visibility: routeMenuOpened ? 'visible' : 'hidden',
						zIndex: '10',
					}}>
					<div className={classes.routeGroup}>
						<Select
							style={{ marginTop: 16, display: 'flex', gap: 16 }}
							labelId='route-label'
							id='route-select'
							value={routeId}
							onChange={e => handleRouteChange(e.target.value)}>
							{routes &&
								routes
									.sort((a, b) => {
										return a.route_number - b.route_number
									})
									.map(route => {
										return (
											<MenuItem
												key={route.id}
												value={route.id}
												style={{ backgroundColor: route.default ? '#f0f0f0' : '#fff' }}>
												<div
													style={{
														display: 'flex',
														gap: 16,
														margin: '0 16px',
													}}>
													<span style={{ display: 'flex' }}>{route.route_number}</span>
													<span style={{ display: 'flex', flex: 1 }}>{route.name}</span>
													{routeProps[route.id] && (
														<span style={{ display: 'flex', fontSize: 10, color: 'green' }}>
															visible
														</span>
													)}
													{route.default === 1 && (
														<span style={{ display: 'flex', fontSize: 10, color: '#bbb' }}>
															default
														</span>
													)}
													{routeProps[route.id]?.expired && (
														<span title='Route has expired edges'>⚠️</span>
													)}
												</div>
											</MenuItem>
										)
									})}
						</Select>

						<div className={classes.totalEdges}>
							<div>
								Committed edges{' '}
								<span style={{ marginLeft: 4, color: '#444', fontWeight: 'bold' }}>
									{
										mapping.filter(m => {
											return !m.newValue
										}).length
									}
								</span>
							</div>
							<div style={{ position: 'absolute', right: 0, bottom: 0 }}>
								Route length{' '}
								<span style={{ marginLeft: 4, color: '#444', fontWeight: 'bold' }}>
									{mapping
										.filter(m => {
											return !m.newValue && m.route_id === routeId
										})
										.reduce((acc, curr) => {
											return (acc += curr.length_km)
										}, 0)
										.toFixed(2)}
									km
								</span>
								<span style={{ color: 'green', marginLeft: 4 }}>
									{'+' +
										mapping
											.filter(m => {
												return m.newValue && m.route_id === routeId
											})
											.reduce((acc, curr) => {
												return (acc += curr.length_km)
											}, 0)
											.toFixed(2)}
								</span>
							</div>
						</div>
					</div>
					<Divider />
					<div style={{ height: 350, overflow: 'auto' }} ref={edgeContainerRef}>
						{loader ? <CircularIndeterminate style={{ zIndex: 1101 }} /> : ''}
						{listSelectedEdges()}
					</div>
					<Divider />
					{renderRouteProps()}
					{renderLonLat()}
					<div
						style={{
							justifyContent: 'center',
							margin: '16px 0',
							textAlign: 'center',
							display: 'flex',
							flex: 1,
						}}>
						<Badge
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							badgeContent={
								mapping.filter(m => {
									return m.newValue
								}).length
							}
							color='secondary'>
							<Button
								style={{ width: 400 }}
								fullWidth
								variant='outlined'
								color='primary'
								disabled={
									mapping.filter(m => {
										return m.newValue
									}).length
										? false
										: true
								}
								onClick={() => {
									setLoader(true)
									props.onCommit(
										mapping.filter(m => {
											return m.newValue
										}),
									)
									setMapping([])
								}}>
								Commit Changes
							</Button>
						</Badge>
					</div>
				</div>
			) : routeMenuOpened && props.adminOption === 'edge_status' ? (
				<div
					style={{
						position: 'absolute',
						top: 90,
						left: 16,
						height: 350,
						width: 355,
						backgroundColor: '#fff',
						zIndex: 1,
						borderRadius: 20,
					}}>
					<div style={{ fontSize: 12, margin: 16 }}>Selected edges </div>
					<div
						style={{
							height: 200,
							overflow: 'auto',
							borderTop: '1px solid #dedede',
							borderBottom: '1px solid #dedede',
						}}>
						{loader ? <CircularIndeterminate style={{ position: 'absolute', top: -45 }} /> : ''}
						{listAllSelectedEdges()}
						{!mappingEdgesStatus.length && !loader ? (
							<div
								style={{
									display: 'flex',
									alignContent: 'center',
									margin: 25,
									height: 200,
									fontSize: 12,
									color: '#444',
									alignItems: 'center',
									justifyContent: 'center',
								}}>
								Click on a map to add edge to this list.
							</div>
						) : (
							''
						)}
					</div>
					<div style={{ display: 'flex', margin: 24 }}>
						<select
							onChange={e => {
								setEdgeStatusValue(e.target.value)
							}}
							style={{
								textAlign: 'center',
								borderRadius: 8,
								width: 90,
								marginRight: 8,
								outline: 'none',
								border: '1px solid #dedede',
							}}>
							<option value={1}>{getStatus(1)}</option>
							<option value={2}>{getStatus(2)}</option>
							<option value={3}>{getStatus(3)}</option>
							<option value={4}>{getStatus(4)}</option>
						</select>
						<button
							className='changeStatus-button'
							onClick={() => {
								setLoader(true)
								props.onEdgesStatusChange(
									mappingEdgesStatus.map(edge => {
										return { ...edge, status: parseInt(edgeStatusValue) }
									}),
								)
								setMappingEdgesStatus([])
							}}
							disabled={!mappingEdgesStatus.length || loader}
							style={{
								borderRadius: 8,
								backgroundColor: '#3f51b5',
								color: '#fff',
								outline: 'none',
								width: 160,
								cursor: 'pointer',
								padding: '8px 16px',
								fontSize: 14,
								border: 'none',
							}}>
							Change status
						</button>
					</div>
				</div>
			) : (
				''
			)}
		</div>
	)
}
