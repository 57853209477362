import { applyTokenRefreshInterceptor } from '@datamap/common-ui'
import axios from 'axios'
import { Component } from 'react'
import { getCityHeader } from '../api/request'
import jwt_decode from 'jwt-decode'
import localStorageService from '../services/localStorageService'

axios.interceptors.request.use(
	config => {
		if (localStorage.getItem('user')) {
			const jwt = JSON.parse(localStorage.getItem('user')).jwt
			config.headers['Authorization'] = jwt
		}

		const user = localStorageService.getUser()
		if (user && user.user_id) {
			config.params = { ...config.params, user_id: user.user_id }
		}

		return config
	},
	error => {
		return Promise.reject(error)
	},
)

applyTokenRefreshInterceptor(axios, {
    idServerUrl: process.env.REACT_APP_ID_SERVER,
    updateToken: token => {
        const tokenDecoded = jwt_decode(token)
        localStorage.setItem(
            'user',
            JSON.stringify({
                user_id: tokenDecoded.sub,
                roles: tokenDecoded.roles,
                exp: tokenDecoded.exp,
                jwt: token,
            }),
        )
    }
})

axios.interceptors.response.use(
	response => response,
	error => {
        if (
            error.response.status === 401
            || (error.response.status === 422 && error.response.data?.messageId === 31)
        ) {
            localStorage.removeItem('user')
            window.location.href = `${getCityHeader()}/login`
        }

		return Promise.reject(error)
	},
)

export default function Authentication(WrappedComponent) {
	return class extends Component {
		constructor(props) {
			super(props)
			const prefix = getCityHeader()

			const user = localStorageService.getUser()
			if (!user) {
				window.location.href = prefix + '/login'
			} else {
				try {
					if (user.exp < new Date().getTime()) {
						window.location.href = prefix + '/login'
					}
				} catch (err) {
					window.location.href = prefix + '/login'
				}
			}
		}

		render() {
			return <WrappedComponent {...this.props} />
		}
	}
}
