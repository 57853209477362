import './style.css'
import { useState } from 'react'
export const PlaceEditor = props => {
	const [newPlace, setNewPlace] = useState({
		idle_id: 201,
	})

	const getIdleIdFromPlaceName = placeName => {
		switch (placeName) {
			case 'Home':
				return 201
			case 'Work':
				return 202
			case 'School / University':
				return 203
			case 'Custom Place':
				return 500
			default:
				return 500
		}
	}
	const renderEditingActions = feature => {
		if (!feature.properties.idle_id) {
			return (
				<div className='placeEditor--editingActionsWrapper'>
					<div className='placeEditor--savePlaceInfo'>Möchten Sie diesen Ort als Favorit speichern?</div>
					<div>
						<input
							className='myRoutes--input'
							type='text'
							placeholder='Geben Sie einen Namen für diesen Ort ein'
							style={{ width: '100%' }}
							onChange={evt => {
								setNewPlace({
									...newPlace,
									name: evt.target.value,
								})
							}}
						/>
					</div>
					<div className='myRoutes--selectActivity'>
						<div style={{ marginBottom: 4 }}>Art des Ortes</div>
						<select
							style={{ width: '100%' }}
							onChange={evt => {
								setNewPlace({
									...newPlace,
									idle_id: getIdleIdFromPlaceName(evt.target.value),
								})
							}}>
							<option id='201'>Zuhause</option>
							<option id='202'>Arbeit</option>
							<option id='203'>Schule / Universität</option>
							<option id='500'>Benutzerdefinierter Ort</option>
						</select>
					</div>
					<div>
						<button
							onClick={() => {
								props.onSavePlace(newPlace)
							}}
							disabled={!newPlace.name || !newPlace.idle_id}
							className='myRoutes--button'
							style={{ width: '100%' }}>
							Save
						</button>
					</div>
				</div>
			)
		}
	}
	const renderRoutingActions = feature => {
		return (
			<div className='placeSearchResults--routingActionsWrapper'>
				<div className='placeSearchResults--routingButtonsWrapper'>
					{props.myRouteStops.start.features.length === 0 || props.startInputFocused.current === true ? (
						<button
							className='placeSearchResults--routingButton'
							onClick={() => {
								props.onSetAsStartPoint(feature)
							}}>
							Set as Start Point
						</button>
					) : (
						''
					)}
					{props.myRouteStops.start.features.length > 0 && props.myRouteStops.destination.features.length > 0 ? (
						<button
							onClick={() => {
								props.onSetAsViaPoint(feature)
							}}
							className='placeSearchResults--routingButton'>
							Include on Route
						</button>
					) : (
						''
					)}
					<button
						onClick={() => {
							props.onSetAsEndPoint(feature)
						}}
						className='placeSearchResults--routingButton'>
						Set as new End Point
					</button>
				</div>
			</div>
		)
	}

	return (
		<div>
			<div className='placeSearchResults--placeEditorHeaderCloseWrapper'>
				<div className='placeSearchResults--placeEditorHeaderClose' onClick={() => props.onClose()}>
					<svg
						stroke='currentColor'
						fill='currentColor'
						stroke-width='0'
						viewBox='0 0 512 512'
						height='16px'
						width='16px'>
						<path d='M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z'></path>
					</svg>
				</div>
				<div className='placeSearchResults--placeName'>{props.placeSelected.properties.name}</div>
				<div className='placeSearchResults--placeBorder'></div>
			</div>
			<div className='placeSearchResults--placeEditor'>
				<div className='placeSearchResults--placeHeaderWrapper'>
					<div className='placeSearchResults--placeEditorHeader'>
						<div className='placeSearchResults--placeEditorHeaderDetails'>
							{props.placeSelected.properties.full_address}
						</div>
					</div>
				</div>
			</div>
			{renderEditingActions(props.placeSelected)}
			{renderRoutingActions(props.placeSelected)}
		</div>
	)
}
