const COUNTRY_FRANCE = 'fr'
const COUNTRY_SWITZERLAND = 'ch'
const COUNTRY_ITALY = 'it'
const COUNTRY_SERBIA = 'rs'
const COUNTRY_GERMANY = 'de'

const CITY_ZUERICH = 'zuerich'
const CITY_ZUERICH_STAGING = 'zuerich_staging'
const CITY_PARIS = 'paris'
const CITY_BASEL = 'basel'
const CITY_BRUNECK = 'bruneck'
const CITY_BELGRADE = 'beograd'
const CITY_BERLIN = 'berlin'
const CITY_MUENCHEN = 'muenchen'
const CITY_FREIBURG = 'freiburg'
const CITY_GOSSAU = 'gossau'

const DOMAIN_COUNTRIES = {
    ch: COUNTRY_SWITZERLAND,
    de: COUNTRY_GERMANY,
}

const COUNTRIES = {
	[COUNTRY_SWITZERLAND]: {
		[CITY_ZUERICH]: {
			latitude: 47.3769,
			longitude: 8.5417,
			zoom: 12,
		},
		[CITY_ZUERICH_STAGING]: {
			latitude: 47.3769,
			longitude: 8.5417,
			zoom: 12,
		},
		[CITY_BASEL]: {
			latitude: 47.559601,
			longitude: 7.588576,
			zoom: 12,
		},
		[CITY_GOSSAU]: {
			latitude: 47.416499,
			longitude: 9.249802,
			zoom: 12,
		},
	},
	[COUNTRY_FRANCE]: {
		[CITY_PARIS]: {
			latitude: 48.8566,
			longitude: 2.3522,
			zoom: 12,
		},
	},
	[COUNTRY_ITALY]: {
		[CITY_BRUNECK]: {
			latitude: 46.796561,
			longitude: 11.937888,
			zoom: 13,
		},
	},
	[COUNTRY_SERBIA]: {
		[CITY_BELGRADE]: {
			latitude: 44.817346,
			longitude: 20.457573,
			zoom: 12,
		},
	},
	[COUNTRY_GERMANY]: {
		[CITY_BERLIN]: {
			latitude: 52.52,
			longitude: 13.405,
			zoom: 12,
		},
		[CITY_MUENCHEN]: {
			latitude: 48.1351,
			longitude: 11.582,
			zoom: 12,
		},
        [CITY_FREIBURG]: {
			latitude: 47.996894,
			longitude: 7.849388,
			zoom: 12,
        },
	},
}

const topLevelDomain = window.location.hostname.split('.').pop()
// Use com as default top level domain
export const TOP_LEVEL_DOMAIN = DOMAIN_COUNTRIES[topLevelDomain] ? topLevelDomain : 'com'
let filteredCountries
if (DOMAIN_COUNTRIES[TOP_LEVEL_DOMAIN]) {
    const country = DOMAIN_COUNTRIES[TOP_LEVEL_DOMAIN]
    filteredCountries = { [country]: COUNTRIES[country] }
} else {
    filteredCountries = COUNTRIES
}

export const AVAILABLE_COUNTRIES = filteredCountries
export const AVAILABLE_CITIES = Object.values(AVAILABLE_COUNTRIES).reduce((previous, cities) => ({ ...previous, ...cities }), {})
export const AVAILABLE_PATHS = Object.entries(AVAILABLE_COUNTRIES).reduce((previous, [country, cities]) => {
	Object.keys(cities).forEach(city => previous.push(`/${city}`, `/${city}/`, `/${country}/${city}`, `/${country}/${city}/`))
	return previous
}, [])
